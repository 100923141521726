import { useTranslation } from 'react-i18next';

const TableFilters = ({ setValue, currentCategory }) => {
  const { t } = useTranslation();

  return (
    <div className='categories__wrapper'>
      <div className='categories__column'>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input B-input visually-hidden'
            name='element-category'
            value={'B'}
            onChange={setValue}
            checked={currentCategory === 'B'}
          />
          <span className='categories__radio B-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item0')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input G-input visually-hidden'
            name='element-category'
            value={'G'}
            onChange={setValue}
            checked={currentCategory === 'G'}
          />
          <span className='categories__radio G-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item1')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input C-input visually-hidden'
            name='element-category'
            value={'C'}
            onChange={setValue}
            checked={currentCategory === 'C'}
          />
          <span className='categories__radio C-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item4')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input A-input visually-hidden'
            name='element-category'
            value={'A'}
            onChange={setValue}
            checked={currentCategory === 'A'}
          />
          <span className='categories__radio A-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item2')}
          </span>
        </label>
      </div>
      <div className='categories__column'>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input H-input visually-hidden'
            name='element-category'
            value={'H'}
            onChange={setValue}
            checked={currentCategory === 'H'}
          />
          <span className='categories__radio H-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item3')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input I-input visually-hidden'
            name='element-category'
            value={'I'}
            onChange={setValue}
            checked={currentCategory === 'I'}
          />
          <span className='categories__radio I-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item5')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input E-input visually-hidden'
            name='element-category'
            value={'E'}
            onChange={setValue}
            checked={currentCategory === 'E'}
          />
          <span className='categories__radio E-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item8')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input D-input visually-hidden'
            name='element-category'
            value={'D'}
            onChange={setValue}
            checked={currentCategory === 'D'}
          />
          <span className='categories__radio D-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item6')}
          </span>
        </label>
      </div>
      <div className='categories__column'>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input L-input visually-hidden'
            name='element-category'
            value={'L'}
            onChange={setValue}
            checked={currentCategory === 'L'}
          />
          <span className='categories__radio L-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item10')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input J-input visually-hidden'
            name='element-category'
            value={'J'}
            onChange={setValue}
            checked={currentCategory === 'J'}
          />
          <span className='categories__radio J-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item7')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input K-input visually-hidden'
            name='element-category'
            value={'K'}
            onChange={setValue}
            checked={currentCategory === 'K'}
          />
          <span className='categories__radio K-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item9')}
          </span>
        </label>
        <label className='categories__item'>
          <input
            type='radio'
            className='categories__input all-input visually-hidden'
            name='element-category'
            value={'all'}
            onChange={setValue}
            checked={currentCategory === 'all'}
          />
          <span className='categories__radio all-radio' />
          <span className='categories__label'>
            {t('array.categ_name_new.item11')}
          </span>
        </label>
      </div>
    </div>
  );
};

export default TableFilters;