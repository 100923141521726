import { Helmet } from 'react-helmet';
import GoToHomePage from '../components/GoToHomePage';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../hooks/useAuth';
import { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import {
  changeCategoryColor,
  changeWhiteColor,
  getCategoryName,
  getValuesWithHTML,
  halfLife
} from '../services/functions';
import { elementCategories } from '../constants/elementCategories';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { allElementsState } from '../states/atoms';
import { search } from '../utils/search';
import {
  getCountry,
  getFirstOpener,
  getGroupAndPeriod,
  getIonCharge,
  getTemperature,
  getTemperatureFromKelvin,
  getValue,
  getYearDiscovered
} from '../utils/element';
import ElectronShell from '../components/ElectronShell';
import ComparePropsItem from '../components/ComparePropsItem';
import OxidationStates from '../components/OxidationStates';
import { colors } from '../constants/colors';
import NfpaCube from '../components/NfpaCube';
import { getNFPA1, getNFPA2, getNFPA3, getNFPA4 } from '../services/nuclearProps';

const CategoryCard = styled.div`
    font-weight: 700;
    font-family: 'OpenSans', 'Arial', sans-serif;
    display: inline-block;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    padding: 12px 20px;
    border-radius: ${props => props.borderRadius};
    font-size: 12px;
    text-transform: uppercase;
`;

const ElementSymbol = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'OpenSans', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 17px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.textColor};
    margin-right: 19px;
`;

const ComparePage = ({ id }) => {
  const itemId = Number(id);
  const [compareItemId, setCompareItemId] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const { getAuthCookie } = useAuth();
  const [elements] = useRecoilState(allElementsState);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [compareData, setCompareData] = useState();

  const getElementData = async (itemId, setData) => {
    setIsLoading(true);
    try {
      const headers = { 'Content-Type': 'application/json' };
      const token = getAuthCookie();
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      await fetch(`${process.env.REACT_APP_API_HOST}/periodic/element/${itemId}`, { method: 'GET', headers })
        .then(data => data.json())
        .then(data => setData(data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const meltingPoint = getTemperature(data?.PropertiesCommon.elementMeltingPoint);
  const compareMeltingPoint = getTemperature(compareData?.PropertiesCommon.elementMeltingPoint);
  const boilingPoint = getTemperature(data?.PropertiesCommon.elementBoilingPoint);
  const compareBoilingPoint = getTemperature(compareData?.PropertiesCommon.elementBoilingPoint);
  const blockUnit = t(`string.translate.read_group_block2`).replace('%1$s', '');
  const elementGroup = data?.PropertiesCommon.elementGroup;
  const compareElementGroup = compareData?.PropertiesCommon.elementGroup;
  const elementSpectrum = data?.OverviewCommon.hasSpectre;
  const compareElementSpectrum = compareData?.OverviewCommon.hasSpectre;
  const ionizationPotentialUnit = t(`string.translate.at7_2`);
  const electricalConductivityUnit = t(`string.translate.es1_value`);
  const molarMagneticUnit = t(`string.translate.es6_value`);
  const resistivityUnit = '(Ω · m)';
  const superconductingPoint = getTemperatureFromKelvin(data?.ElectromagneticCommon.es_temp);
  const compareSuperconductingPoint = getTemperatureFromKelvin(compareData?.ElectromagneticCommon.es_temp);
  const debyeTemperature = getTemperatureFromKelvin(data?.GridPro.debyeTemperature);
  const compareDebyeTemperature = getTemperatureFromKelvin(compareData?.GridPro.debyeTemperature);
  let gridStructureNum = data?.GridPro?.gridStructureNum;
  if (gridStructureNum) {
    gridStructureNum = gridStructureNum.split(' | ')[0];
  }
  let compareGridStructureNum = compareData?.GridPro?.gridStructureNum;
  if (compareGridStructureNum) {
    compareGridStructureNum = compareGridStructureNum.split(' | ')[0];
  }
  let nfpaCube = [];
  if (data?.NucleusPro?.nfpaCube) {
    nfpaCube = data?.NucleusPro?.nfpaCube.split(',').map(item => {
      const number = Number(item);
      return isNaN(number) ? item : number;
    });
  }
  let compareNfpaCube = [];
  if (compareData?.NucleusPro?.nfpaCube) {
    compareNfpaCube = compareData?.NucleusPro?.nfpaCube.split(',').map(item => {
      const number = Number(item);
      return isNaN(number) ? item : number;
    });
  }

  useEffect(() => {
    getElementData(itemId, setData);
    getElementData(compareItemId, setCompareData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, compareItemId]);

  const getItems = () => {
    let items = [];
    elements.forEach(element => {
      items.push(
        <button
          key={element.number}
          onClick={() => setCompareItemId(element.number)}
          className='search__elements-item compare__modal-element-item'
          data-name={t(`table.element${element.number}.name`)}
          data-latin-name={element.OverviewCommon.latinName}
          data-symbol={element.symbol}
          data-year={element.OverviewCommon.elementOpenedYear}
          data-weight={element.PropertiesCommon.elementMasse}
          data-number={element.number.toString()}
        >
          <ElementSymbol
            backgroundColor={() => changeCategoryColor(elementCategories[element.number - 1])}
            textColor={() => changeWhiteColor(elementCategories[element.number - 1])}
          >
            {element.symbol}
          </ElementSymbol>
          <div className='search__element-name-and-mass'>
            <span className='search__element-name'>{t(`table.element${element.number}.name`)}</span>
            <span className='search__element-mass'>
              {`${element.PropertiesCommon.elementMasse} ${t('string.translate.read_gramm_moll')}`}
            </span>
          </div>
        </button>
      );
    });
    return items;
  };

  if (!compareItemId) {
    return (
      <section className='compare__modal-container'>
        <GoToHomePage classNames='go-to-homepage go-to-homepage__full-size' link={`/element/${itemId}`} />
        <div className='compare__modal'>
          <div className='compare__modal-header'>
            <button className='read-element__go-back-btn' />
            <div className='compare__modal-title'>
              <h3>{t('string.translate.compare_select_second_element')}</h3>
              <p>{t('string.translate.compare_select_second_element_tost')}</p>
            </div>
          </div>
          <div className='compare__input-wrapper'>
            <input
              className='search__input'
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
              autoComplete='off'
              placeholder={t('string.translate.rm_search')}
            />
          </div>
          <div className='compare__modal-list'>{search(getItems(), inputValue, 'all')}</div>
        </div>
      </section>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${itemId} | ${t(`table.element${itemId}.symbol`)} – ${t(`table.element${itemId}.name`)}`}</title>
      </Helmet>
      <GoToHomePage classNames={'go-to-homepage__full-size'} />
      <section className='compare'>
        {!isLoading ? (
          <>
            <header
              className='compare__header'
              style={{
                background: `linear-gradient(90deg, ${changeCategoryColor(
                  elementCategories[itemId - 1]
                )} 31.25%, ${changeCategoryColor(
                  elementCategories[compareItemId - 1]
                )} 72.92%)`
              }}
            >
              <div className='compare__header-dark-gradient' />
              <div className='compare__header-container'>
                <div className='compare__header-top'>
                  <Link className='read-element__go-back-btn' to='/' />
                  <div className='compare__elements-images'>
                    <div className='compare__element-image-wrapper'>
                      {data && data.number === itemId && data.OverviewCommon.hasImage && (
                        <picture>
                          <source
                            type='image/avif'
                            srcSet={`${require(`../assets/images/elements-avif/element_${itemId}.avif`)} 1x,
        				${require(`../assets/images/elements-2x-avif/element_${itemId}.avif`)} 2x`}
                          />
                          <source
                            type='image/webp'
                            srcSet={`${require(`../assets/images/elements-webp/element_${itemId}.webp`)} 1x,
        				${require(`../assets/images/elements-2x-webp/element_${itemId}.webp`)} 2x`}
                          />
                          <img
                            src={require(`../assets/images/elements-jpg/element_${itemId}.jpg`)}
                            srcSet={`${require(`../assets/images/elements-2x-jpg/element_${itemId}.jpg`)} 2x`}
                            alt=''
                          />
                        </picture>
                      )}
                    </div>
                    <div className='compare__element-image-wrapper'>
                      {compareData &&
                        compareData.number === compareItemId &&
                        compareData.OverviewCommon.hasImage && (
                          <picture>
                            <source
                              type='image/avif'
                              srcSet={`${require(`../assets/images/elements-avif/element_${compareItemId}.avif`)} 1x,
        				${require(`../assets/images/elements-2x-avif/element_${compareItemId}.avif`)} 2x`}
                            />
                            <source
                              type='image/webp'
                              srcSet={`${require(`../assets/images/elements-webp/element_${compareItemId}.webp`)} 1x,
        				${require(`../assets/images/elements-2x-webp/element_${compareItemId}.webp`)} 2x`}
                            />
                            <img
                              src={require(`../assets/images/elements-jpg/element_${compareItemId}.jpg`)}
                              srcSet={`${require(`../assets/images/elements-2x-jpg/element_${compareItemId}.jpg`)} 2x`}
                              alt=''
                            />
                          </picture>
                        )}
                    </div>
                  </div>
                </div>
                <div className='compare__elements-info'>
                  <div className='compare__element-info'>
                    <div className='read-element__groups'>
                      {data?.OverviewCommon.elementRadioactivity && (
                        <span className='read-element__radioactive' style={{ borderRadius: '0 4px 4px 0' }}>
                          {t(`string.translate.ys1`).replace(':', '')}
                        </span>
                      )}
                      <CategoryCard
                        backgroundColor={changeCategoryColor(elementCategories[itemId - 1])}
                        textColor={changeWhiteColor(elementCategories[itemId - 1])}
                        borderRadius='0 4px 4px 0'
                      >
                        <span className='read-element__category'>{getCategoryName(itemId)}</span>
                      </CategoryCard>
                      <div className='read-element__selected-element'>
                        <span className='read-element__name'>{t(`table.element${itemId}.name`)}</span>
                        <div className='read-element__symbol-wrapper'>
                          <span className='read-element__symbol'>{data?.symbol}</span>
                          <span className='read-element__number'>{itemId}</span>
                        </div>
                        <span className='read-element__weight'>
                          {Number(data?.PropertiesCommon.elementMasse).toFixed(4)}
                          <span> {t(`string.translate.read_gramm_moll`)}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='compare__vs-badge'>VS</div>
                  <div className='compare__element-info'>
                    <div className='read-element__groups'>
                      {compareData?.OverviewCommon.elementRadioactivity && (
                        <span className='read-element__radioactive'>{t(`string.translate.ys1`).replace(':', '')}</span>
                      )}
                      <CategoryCard
                        backgroundColor={changeCategoryColor(elementCategories[compareItemId - 1])}
                        textColor={changeWhiteColor(elementCategories[compareItemId - 1])}
                        borderRadius='4px 0 0 4px'
                      >
                        <span className='read-element__category'>{getCategoryName(compareItemId)}</span>
                      </CategoryCard>
                      <div className='read-element__selected-element'>
                        <span className='read-element__name'>{t(`table.element${compareItemId}.name`)}</span>
                        <div className='read-element__symbol-wrapper'>
                          <span className='read-element__symbol'>{compareData?.symbol}</span>
                          <span className='read-element__number'>{compareItemId}</span>
                        </div>
                        <span className='read-element__weight'>
                          {Number(compareData?.PropertiesCommon.elementMasse).toFixed(4)}
                          <span> {t(`string.translate.read_gramm_moll`)}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className='compare__info-container'>
              <div className='read-element__props-header read-element__props-header--overview'>
                {t(`string.translate.read_obsh_head`)}
              </div>
              <ComparePropsItem
                label={t(`string.translate.read_latin_name`)}
                data={data?.OverviewCommon.latinName}
                compareData={compareData?.OverviewCommon.latinName}
                withoutCompare
              />
              <ComparePropsItem
                label={t(`string.translate.read_eng_name`)}
                data={data?.OverviewCommon.englishName}
                compareData={compareData?.OverviewCommon.englishName}
                withoutCompare
              />
              <ComparePropsItem
                label={t(`string.translate.read_year_open`)}
                data={getYearDiscovered(data?.OverviewCommon.elementOpenedYear)}
                compareData={getYearDiscovered(compareData?.OverviewCommon.elementOpenedYear)}
              />
              <ComparePropsItem
                label={t(`string.translate.read_first_opener`)}
                data={getFirstOpener(data?.OverviewCommon.elementOpener)}
                compareData={getFirstOpener(compareData?.OverviewCommon.elementOpener)}
                withoutCompare
              />
              <ComparePropsItem
                label={t(`string.translate.read_opened_country`)}
                data={data?.OverviewCommon?.countryOpener ? getCountry(data?.OverviewCommon?.countryOpener) : null}
                compareData={
                  compareData?.OverviewCommon?.countryOpener
                    ? getCountry(compareData?.OverviewCommon?.countryOpener)
                    : null
                }
                withoutCompare
              />
              <ComparePropsItem
                label={t(`string.translate.cost`)}
                data={data?.OverviewCommon.elementPrice ? `${data?.OverviewCommon.elementPrice} USD` : '----'}
                compareData={compareData?.OverviewCommon.elementPrice ? `${compareData?.OverviewCommon.elementPrice} USD` : '----'}
              />
              <ComparePropsItem
                label={t(`string.translate.sm12`)}
                data={data?.OverviewCommon.casNumber}
                compareData={compareData?.OverviewCommon.casNumber}
              />
              <div className='compare__electron-shell compare__electron-shell--first'>
                <ElectronShell data={data?.OverviewCommon.elementShell} />
                <div className='compare__electron-shell-value-wrapper'>
                  <p className='compare__electron-shell-value-info'>
                    {t('string.translate.el_obolochka')} {data?.symbol}
                  </p>
                  <p className='compare__electron-shell-value'>{data?.OverviewCommon.elementShell}</p>
                </div>
              </div>
              <div className='compare__electron-shell compare__electron-shell--second'>
                <div className='compare__electron-shell-value-wrapper'>
                  <p className='compare__electron-shell-value-info'>
                    {t('string.translate.el_obolochka')} {compareData?.symbol}
                  </p>
                  <p className='compare__electron-shell-value'>{compareData?.OverviewCommon.elementShell}</p>
                </div>
                <ElectronShell data={compareData?.OverviewCommon.elementShell} />
              </div>
              <div className='read-element__props-header read-element__props-header--properties'>
                {t(`string.translate.calc_properties`)}
              </div>
              <div className='read-element__anc-table compare__anc-table'>
                <div className='read-element__anc-item read-element__anc-item--electron'>
                  <div className='read-element__anc-table-key read-element__anc-table-key--electron'>
                    {t(`string.translate.text_electron`)}
                  </div>
                  <div className='read-element__anc-table-value read-element__anc-table-value--electron'>
                    {data?.OverviewCommon.elementE} / {compareData?.OverviewCommon.elementE}
                  </div>
                </div>
                <div className='read-element__anc-item read-element__anc-item--proton'>
                  <div className='read-element__anc-table-key read-element__anc-table-key--proton'>
                    {t(`string.translate.text_proton`)}
                  </div>
                  <div className='read-element__anc-table-value read-element__anc-table-value--proton'>
                    {data?.OverviewCommon.elementP} / {compareData?.OverviewCommon.elementP}
                  </div>
                </div>
                <div className='read-element__anc-item read-element__anc-item--neutron'>
                  <div className='read-element__anc-table-key read-element__anc-table-key--neutron'>
                    {t(`string.translate.text_neutron`)}
                  </div>
                  <div className='read-element__anc-table-value read-element__anc-table-value--neutron'>
                    {data?.OverviewCommon.elementN} / {compareData?.OverviewCommon.elementN}
                  </div>
                </div>
              </div>
              <ComparePropsItem
                label={t(`string.translate.read_atom_number`)}
                data={itemId}
                compareData={compareItemId}
              />
              <ComparePropsItem
                label={t(`string.translate.read_atom_massa`)}
                data={`${data?.PropertiesCommon.elementMasse} \n ${t(`string.translate.read_gramm_moll`)}`}
                compareData={`${compareData?.PropertiesCommon.elementMasse} \n ${t(`string.translate.read_gramm_moll`)}`}
              />
              <ComparePropsItem
                label={t(`string.translate.read_density`)}
                data={`${data?.PropertiesCommon.elementDensity} \n ${t(`string.translate.read_gramm_santim`)}`}
                compareData={`${compareData?.PropertiesCommon.elementDensity} \n ${t(`string.translate.read_gramm_santim`)}`}
              />
              <ComparePropsItem
                label={t(`string.translate.read_temp1`)}
                data={
                  <span className='read-element__props-value'>
                    {meltingPoint.tempValueCelsius !== '**' ? (
                      <span>
                        {meltingPoint.tempValueCelsius}
                        <span className='celsius'>°C</span><br />
                        {meltingPoint.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span><br />
                        {meltingPoint.tempValueKelvin.toFixed(2)}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
                compareData={
                  <span className='read-element__props-value'>
                    {compareMeltingPoint.tempValueCelsius !== '**' ? (
                      <span>
                        {compareMeltingPoint.tempValueCelsius}
                        <span className='celsius'>°C</span><br />
                        {compareMeltingPoint.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span><br />
                        {compareMeltingPoint.tempValueKelvin.toFixed(2)}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
              />
              <ComparePropsItem
                label={t(`string.translate.read_temp2`)}
                data={
                  <span className='read-element__props-value'>
                    {boilingPoint.tempValueCelsius !== '**' ? (
                      <span>
                        {boilingPoint.tempValueCelsius}
                        <span className='celsius'>°C</span><br />
                        {boilingPoint.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span><br />
                        {boilingPoint.tempValueKelvin.toFixed(2)}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
                compareData={
                  <span className='read-element__props-value'>
                    {compareBoilingPoint.tempValueCelsius !== '**' ? (
                      <span>
                        {compareBoilingPoint.tempValueCelsius}
                        <span className='celsius'>°C</span><br />
                        {compareBoilingPoint.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span><br />
                        {compareBoilingPoint.tempValueKelvin.toFixed(2)}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
              />
              <ComparePropsItem
                label={t(`string.translate.rs2`)}
                data={data?.PropertiesCommon.elValence}
                compareData={compareData?.PropertiesCommon.elValence}
              />
              <ComparePropsItem
                label={t(`string.translate.read_group_block1`)}
                data={`${data?.PropertiesCommon.elementBlock} ${blockUnit}`}
                compareData={`${compareData?.PropertiesCommon.elementBlock} ${blockUnit}`}
              />
              <ComparePropsItem
                label={t(`string.translate.read_period_group`)}
                data={elementGroup && getGroupAndPeriod(elementGroup).groupElementPeriod}
                compareData={compareElementGroup && getGroupAndPeriod(compareElementGroup).groupElementPeriod}
              />
              <ComparePropsItem
                label={t(`string.translate.read_group_group`)}
                data={
                  <span className='read-element__props-value'>
                    {elementGroup &&
                    getGroupAndPeriod(elementGroup).groupElementGroup.includes('+')
                      ? getGroupAndPeriod(elementGroup).groupElementGroup.replace(
                        '+',
                        `${t('string.translate.element_group_a')}`
                      )
                      : null}
                    {elementGroup &&
                    getGroupAndPeriod(elementGroup).groupElementGroup.includes('=')
                      ? getGroupAndPeriod(elementGroup).groupElementGroup.replace(
                        '=',
                        `${t('string.translate.element_group_b')}`
                      )
                      : null}
                  </span>
                }
                compareData={
                  <span className='read-element__props-value'>
                    {compareElementGroup &&
                    getGroupAndPeriod(compareElementGroup).groupElementGroup.includes('+')
                      ? getGroupAndPeriod(compareElementGroup).groupElementGroup.replace(
                        '+',
                        `${t('string.translate.element_group_a')}`
                      )
                      : null}
                    {compareElementGroup &&
                    getGroupAndPeriod(compareElementGroup).groupElementGroup.includes('=')
                      ? getGroupAndPeriod(compareElementGroup).groupElementGroup.replace(
                        '=',
                        `${t('string.translate.element_group_b')}`
                      )
                      : null}
                  </span>
                }
              />
              <div className='compare__spectrum-wrapper'>
                <div className='compare__spectrum-item'>
                  <span className='read-element__props-key'>
                    {t(`string.translate.read_spectr`)} {t(`table.element${itemId}.name`)}
                  </span>
                  <span className='read-element__props-value'>
                    {data && data?.number === itemId && elementSpectrum ? (
                      <picture>
                        <source
                          type='image/webp'
                          srcSet={`${require(`../assets/images/emission-spectrum-webp/element_spec_${itemId}.webp`)}`}
                        />
                        <img
                          className='read-element__spectrum-image'
                          src={require(`../assets/images/emission-spectrum/element_spec_${itemId}.jpg`)}
                          alt=''
                        />
                      </picture>
                    ) : (
                      '----'
                    )}
                  </span>
                </div>
                <div className='compare__spectrum-item'>
                  <span className='read-element__props-key'>
                    {t(`string.translate.read_spectr`)} {t(`table.element${compareItemId}.name`)}
                  </span>
                  <span className='read-element__props-value'>
                    {data &&
                    compareData?.number === compareItemId &&
                    compareElementSpectrum ? (
                      <picture>
                        <source
                          type='image/webp'
                          srcSet={`${require(`../assets/images/emission-spectrum-webp/element_spec_${compareItemId}.webp`)}`}
                        />
                        <img
                          className='read-element__spectrum-image'
                          src={require(`../assets/images/emission-spectrum/element_spec_${compareItemId}.jpg`)}
                          alt=''
                        />
                      </picture>
                    ) : (
                      '----'
                    )}
                  </span>
                </div>
              </div>
              <div className='read-element__props-header read-element__props-header--termodynamic-props'>
                {t(`string.translate.ts0`)}
              </div>
              <ComparePropsItem
                label={t(`string.translate.ts1`)}
                data={
                  data?.PropertiesCommon?.aggregationState !== null
                    ? t(`array.array_ts.item${data?.PropertiesCommon?.aggregationState}`)
                    : '----'
                }
                compareData={
                  compareData?.PropertiesCommon?.aggregationState !== null
                    ? t(`array.array_ts.item${compareData?.PropertiesCommon?.aggregationState}`)
                    : '----'
                }
              />
              <ComparePropsItem
                label={t(`string.translate.ts2`)}
                data={
                  data?.ThermoPro?.fusionHeat
                    ? `${data.ThermoPro.fusionHeat} \n ${t('string.translate.ts2_value')}`
                    : '----'
                }
                compareData={
                  compareData?.ThermoPro?.fusionHeat
                    ? `${compareData.ThermoPro.fusionHeat} \n ${t('string.translate.ts2_value')}`
                    : '----'
                }
              />
              <ComparePropsItem
                label={t(`string.translate.ts3`)}
                data={
                  data?.ThermoPro?.specificHeat
                    ? `${data.ThermoPro.specificHeat} \n ${t('string.translate.ts3_value')}`
                    : '----'
                }
                compareData={
                  compareData?.ThermoPro?.specificHeat
                    ? `${compareData.ThermoPro.specificHeat} \n ${t('string.translate.ts3_value')}`
                    : '----'
                }
              />
              <ComparePropsItem
                label={t(`string.translate.ts4`)}
                data={
                  <span>
                    {data?.ThermoPro?.thermalExpansion ? (
                      <span>
                        <span
                          dangerouslySetInnerHTML={{ __html: data?.ThermoPro?.thermalExpansion }}
                        /><br />
                        (°C<sup>-1</sup>)
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
                compareData={
                  <span>
                    {compareData?.ThermoPro?.thermalExpansion ? (
                      <span>
                        <span
                          dangerouslySetInnerHTML={{ __html: compareData?.ThermoPro?.thermalExpansion }}
                        /><br />
                        (°C<sup>-1</sup>)
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
              />
              <ComparePropsItem
                label={t(`string.translate.ts5`)}
                data={
                  data?.ThermoPro?.vaporizationHeat
                    ? `${data.ThermoPro.vaporizationHeat} \n ${t('string.translate.ts2_value')}`
                    : '----'
                }
                compareData={
                  compareData?.ThermoPro?.vaporizationHeat
                    ? `${compareData.ThermoPro.vaporizationHeat} \n ${t('string.translate.ts2_value')}`
                    : '----'
                }
              />
              <div className='read-element__props-header read-element__props-header--atomic-props'>
                {t(`string.translate.at0`)}
              </div>
              <ComparePropsItem
                label={t(`string.translate.el_config`)}
                data={data?.OverviewCommon.elementConfiguration}
                compareData={compareData?.OverviewCommon.elementConfiguration}
              />
              <div className='compare__spectrum-wrapper'>
                <OxidationStates
                  oxidationStates={data?.AtomicCommon.oxidationState}
                  elementName={t(`table.element${itemId}.name`)}
                />
                <OxidationStates
                  oxidationStates={compareData?.AtomicCommon.oxidationState}
                  elementName={t(`table.element${compareItemId}.name`)}
                />
              </div>
              <ComparePropsItem
                label={t(`string.translate.at6`)}
                data={
                  data?.AtomicCommon.ionCharge ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getIonCharge(itemId, data?.AtomicCommon.ionCharge)}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.AtomicCommon.ionCharge ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getIonCharge(compareItemId, compareData?.AtomicCommon.ionCharge)}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={t(`string.translate.at7`)}
                data={`${data?.AtomicCommon.ionizationPotential} ${ionizationPotentialUnit}`}
                compareData={`${compareData?.AtomicCommon.ionizationPotential} ${ionizationPotentialUnit}`}
              />
              <ComparePropsItem
                label={t(`string.translate.at3`)}
                data={`${getValue(data?.AtomicCommon.atomicRadius, ' (pm)')}`}
                compareData={`${getValue(compareData?.AtomicCommon.atomicRadius, ' (pm)')}`}
              />
              <ComparePropsItem
                label={t(`string.translate.at3`)}
                data={`${getValue(data?.AtomicCommon.atomicRadius, ' (pm)')}`}
                compareData={`${getValue(compareData?.AtomicCommon.atomicRadius, ' (pm)')}`}
              />
              <ComparePropsItem
                label={t(`string.translate.at4`)}
                data={`${getValue(data?.AtomicCommon.covalentRadius, ' (pm)')}`}
                compareData={`${getValue(compareData?.AtomicCommon.covalentRadius, ' (pm)')}`}
              />
              <ComparePropsItem
                label={t(`string.translate.at5`)}
                data={`${getValue(data?.AtomicCommon.vanDerWaalsRadius, ' (pm)')}`}
                compareData={`${getValue(compareData?.AtomicCommon.vanDerWaalsRadius, ' (pm)')}`}
              />
              <div className='read-element__props-header read-element__props-header--electromagnetic-props'>
                {t(`string.translate.es0`)}
              </div>
              <ComparePropsItem
                label={t(`string.translate.es1`)}
                data={
                  data?.ElectromagneticCommon.es_electro ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(
                        data?.ElectromagneticCommon.es_electro,
                        `\n ${electricalConductivityUnit}`
                      )}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.ElectromagneticCommon.es_electro ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(
                        compareData?.ElectromagneticCommon.es_electro,
                        `\n ${electricalConductivityUnit}`
                      )}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={t(`string.translate.es2`)}
                data={
                  data?.ElectromagneticCommon.es_etype
                    ? t(`array.es_etype.item${data?.ElectromagneticCommon.es_etype - 1}`)
                    : '----'
                }
                compareData={
                  compareData?.ElectromagneticCommon.es_etype
                    ? t(`array.es_etype.item${compareData?.ElectromagneticCommon.es_etype - 1}`)
                    : '----'
                }
              />
              <ComparePropsItem
                label={t(`string.translate.es3`)}
                data={
                  data?.ElectromagneticCommon.es_mtype
                    ? t(`array.es_mtype.item${data?.ElectromagneticCommon.es_mtype - 1}`)
                    : '----'
                }
                compareData={
                  compareData?.ElectromagneticCommon.es_mtype
                    ? t(`array.es_mtype.item${compareData?.ElectromagneticCommon.es_mtype - 1}`)
                    : '----'
                }
              />
              <ComparePropsItem
                label={t(`string.translate.es4`)}
                data={
                  data?.ElectromagneticCommon.es_omvospr ? (
                    <span dangerouslySetInnerHTML={getValuesWithHTML(data?.ElectromagneticCommon.es_omvospr)} />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.ElectromagneticCommon.es_omvospr ? (
                    <span dangerouslySetInnerHTML={getValuesWithHTML(compareData?.ElectromagneticCommon.es_omvospr)} />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={t(`string.translate.es5`)}
                data={
                  data?.ElectromagneticCommon.es_umvospr ? (
                    <span dangerouslySetInnerHTML={getValuesWithHTML(data?.ElectromagneticCommon.es_umvospr)} />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.ElectromagneticCommon.es_umvospr ? (
                    <span dangerouslySetInnerHTML={getValuesWithHTML(compareData?.ElectromagneticCommon.es_umvospr)} />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={t(`string.translate.es6`)}
                data={
                  data?.ElectromagneticCommon.es_mmvospr ? (
                    <span
                      dangerouslySetInnerHTML={getValuesWithHTML(
                        data?.ElectromagneticCommon.es_mmvospr,
                        `\n ${molarMagneticUnit}`
                      )}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.ElectromagneticCommon.es_mmvospr ? (
                    <span
                      dangerouslySetInnerHTML={getValuesWithHTML(
                        compareData?.ElectromagneticCommon.es_mmvospr,
                        `\n ${molarMagneticUnit}`
                      )}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={t(`string.translate.es7`)}
                data={
                  data?.ElectromagneticCommon.es_udel ? (
                    <span
                      dangerouslySetInnerHTML={getValuesWithHTML(
                        data?.ElectromagneticCommon.es_udel,
                        `\n ${resistivityUnit}`
                      )}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.ElectromagneticCommon.es_udel ? (
                    <span
                      dangerouslySetInnerHTML={getValuesWithHTML(
                        compareData?.ElectromagneticCommon.es_udel,
                        `\n ${resistivityUnit}`
                      )}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={t(`string.translate.es8`)}
                data={
                  <span className='read-element__props-value'>
                    {superconductingPoint.tempValueKelvin !== null ? (
                      <span>
                        {superconductingPoint.tempValueCelsius.toFixed(2)}
                        <span className='celsius'>°C</span> <br />
                        {superconductingPoint.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span> <br />
                        {superconductingPoint.tempValueKelvin}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
                compareData={
                  <span className='read-element__props-value'>
                    {compareSuperconductingPoint.tempValueKelvin !== null ? (
                      <span>
                        {compareSuperconductingPoint.tempValueCelsius.toFixed(2)}
                        <span className='celsius'>°C</span><br />
                        {compareSuperconductingPoint.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span><br />
                        {compareSuperconductingPoint.tempValueKelvin}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
              />
              <div className='read-element__props-header read-element__props-header--grid-parameters'>
                {t(`string.translate.kr0`)}
              </div>
              <div className='compare__grids-wrapper'>
                {!!gridStructureNum ? (
                  <picture>
                    <source
                      type='image/webp'
                      srcSet={`${require(`../assets/images/crystal-grid/webp/struct${gridStructureNum}.webp`)} 1x`}
                    />
                    <img
                      className='read-element__crystal-grid-image'
                      src={require(`../assets/images/crystal-grid/gif/struct${gridStructureNum}.gif`)}
                      alt=''
                    />
                  </picture>
                ) : (
                  ''
                )}
                {!!compareGridStructureNum ? (
                  <picture>
                    <source
                      type='image/webp'
                      srcSet={`${require(`../assets/images/crystal-grid/webp/struct${compareGridStructureNum}.webp`)} 1x`}
                    />
                    <img
                      className='read-element__crystal-grid-image'
                      src={require(`../assets/images/crystal-grid/gif/struct${compareGridStructureNum}.gif`)}
                      alt=''
                    />
                  </picture>
                ) : (
                  ''
                )}
              </div>
              <ComparePropsItem
                label={t(`string.translate.kr1`)}
                data={gridStructureNum !== null ? t(`array.reshetka.item${gridStructureNum - 1}`) : '----'}
                compareData={compareGridStructureNum !== null ? t(`array.reshetka.item${compareGridStructureNum - 1}`) : '----'}
              />
              <ComparePropsItem
                label={t(`string.translate.kr2`)}
                data={data?.GridPro.gridParams ? getValue(data?.GridPro.gridParams.split(' | ')[0]) : '----'}
                compareData={data?.GridPro.gridParams ? getValue(compareData?.GridPro.gridParams ? compareData?.GridPro.gridParams.split(' | ')[0] : null) : '----'}
              />
              <ComparePropsItem
                label={t(`string.translate.kr3`)}
                data={data?.GridPro.ratio}
                compareData={compareData?.GridPro.ratio}
              />
              <ComparePropsItem
                label={t(`string.translate.kr4`)}
                data={
                  <span className='read-element__props-value'>
                    {debyeTemperature.tempValueKelvin !== null ? (
                      <span>
                        {debyeTemperature.tempValueCelsius.toFixed(2)}
                        <span className='celsius'>°C</span><br />
                        {debyeTemperature.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span><br />
                        {debyeTemperature.tempValueKelvin}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
                compareData={
                  <span className='read-element__props-value'>
                    {compareDebyeTemperature.tempValueKelvin !== null ? (
                      <span>
                        {compareDebyeTemperature.tempValueCelsius.toFixed(2)}
                        <span className='celsius'>°C</span><br />
                        {compareDebyeTemperature.tempValueFarenheit.toFixed(2)}
                        <span className='fahrenheit'>°F</span><br />
                        {compareDebyeTemperature.tempValueKelvin}
                        <span className='kelvin'>K</span>
                      </span>
                    ) : (
                      '----'
                    )}
                  </span>
                }
              />
              <div className='read-element__props-header read-element__props-header--additional-info'>
                {t(`string.translate.sm0`)}
              </div>
              <ComparePropsItem
                label={t(`string.translate.sm1`)}
                data={
                  data?.AdditionalPro?.elementColor ? (
                    <div
                      className='read-element__element-color'
                      style={{ backgroundColor: `#${colors[data?.AdditionalPro?.elementColor]}` }}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.AdditionalPro?.elementColor ? (
                    <div
                      className='read-element__element-color'
                      style={{ backgroundColor: `#${colors[compareData?.AdditionalPro?.elementColor]}` }}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={t(`string.translate.sm12`)}
                data={getValue(data?.OverviewCommon.casNumber)}
                compareData={getValue(compareData?.OverviewCommon.casNumber)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm16`)}
                data={getValue(data?.AdditionalPro.numberCID)}
                compareData={getValue(compareData?.AdditionalPro.numberCID)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm17`)}
                data={getValue(data?.AdditionalPro.numberRTEC)}
                compareData={getValue(compareData?.AdditionalPro.numberRTEC)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm2`)}
                data={getValue(data?.AdditionalPro.brinellHardness, '\n (MPa)')}
                compareData={getValue(compareData?.AdditionalPro.brinellHardness, '\n (MPa)')}
              />
              <ComparePropsItem
                label={t(`string.translate.sm6`)}
                data={getValue(data?.AdditionalPro.mohsHardness)}
                compareData={getValue(compareData?.AdditionalPro.mohsHardness)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm15`)}
                data={getValue(data?.AdditionalPro.vickersHardness)}
                compareData={getValue(compareData?.AdditionalPro.vickersHardness)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm3`)}
                data={getValue(data?.AdditionalPro.bulkModulus, '\n (GPa)')}
                compareData={getValue(compareData?.AdditionalPro.bulkModulus, '\n (GPa)')}
              />
              <ComparePropsItem
                label={t(`string.translate.sm11`)}
                data={getValue(data?.AdditionalPro.youngModulus, '\n (GPa)')}
                compareData={getValue(compareData?.AdditionalPro.youngModulus, '\n (GPa)')}
              />
              <ComparePropsItem
                label={t(`string.translate.sm5`)}
                data={getValue(data?.AdditionalPro.liquidDensity, `\n ${t('string.translate.read_gramm_santim')}`)}
                compareData={getValue(compareData?.AdditionalPro.liquidDensity, `\n ${t('string.translate.read_gramm_santim')}`)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm7`)}
                data={getValue(data?.AdditionalPro.molarValue, `\n ${t('string.translate.sm7_2')}`)}
                compareData={getValue(compareData?.AdditionalPro.molarValue, `\n ${t('string.translate.sm7_2')}`)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm13`)}
                data={getValue(data?.AdditionalPro.poissonRatio, '\n (μ)')}
                compareData={getValue(compareData?.AdditionalPro.poissonRatio, '\n (μ)')}
              />
              <ComparePropsItem
                label={t(`string.translate.sm14`)}
                data={getValue(data?.AdditionalPro.shearModulus, '\n (GPa)')}
                compareData={getValue(compareData?.AdditionalPro.shearModulus, '\n (GPa)')}
              />
              <ComparePropsItem
                label={t(`string.translate.sm8`)}
                data={getValue(data?.AdditionalPro.soundSpeed, `\n ${t('string.translate.sm8_value')}`)}
                compareData={getValue(compareData?.AdditionalPro.soundSpeed, `\n ${t('string.translate.sm8_value')}`)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm9`)}
                data={getValue(data?.AdditionalPro.refractiveIndex)}
                compareData={getValue(compareData?.AdditionalPro.refractiveIndex)}
              />
              <ComparePropsItem
                label={t(`string.translate.sm10`)}
                data={getValue(data?.AdditionalPro.thermalConductivity, `\n ${t('string.translate.sm10_2')}`)}
                compareData={getValue(compareData?.AdditionalPro.thermalConductivity, `\n ${t('string.translate.sm10_2')}`)}
              />
              <div className='read-element__props-header read-element__props-header--reactivity'>
                {t(`string.translate.rs0`)}
              </div>
              <ComparePropsItem
                label={t(`string.translate.rs1`)}
                data={getValue(data?.ReactivityCommon.electronegativity)}
                compareData={getValue(compareData?.ReactivityCommon.electronegativity)}
              />
              <ComparePropsItem
                label={t(`string.translate.rs2`)}
                data={getValue(data?.PropertiesCommon.elValence)}
                compareData={getValue(compareData?.PropertiesCommon.elValence)}
              />
              <ComparePropsItem
                label={t(`string.translate.rs3`)}
                data={getValue(data?.ReactivityCommon.atomElectronEnergy, `\n ${t(`string.translate.electro_column3`)}`)}
                compareData={getValue(compareData?.ReactivityCommon.atomElectronEnergy, `\n ${t(`string.translate.electro_column3`)}`)}
              />
              <div className='read-element__props-header read-element__props-header--nuclear-props'>
                {t(`string.translate.ys0`)}
              </div>
              <ComparePropsItem
                label={t(`string.translate.ys1`)}
                data={
                  data?.OverviewCommon.elementRadioactivity
                    ? t('string.translate.ys_yes')
                    : t('string.translate.ys_no')
                }
                compareData={
                  compareData?.OverviewCommon.elementRadioactivity
                    ? t('string.translate.ys_yes')
                    : t('string.translate.ys_no')
                }
              />
              <ComparePropsItem
                label={t(`string.translate.ys2`)}
                data={halfLife(data?.NucleusPro?.halfLife)}
                compareData={halfLife(compareData?.NucleusPro?.halfLife)}
              />
              <ComparePropsItem
                label={t(`string.translate.ys3`)}
                data={halfLife(data?.NucleusPro?.lifetime)}
                compareData={halfLife(compareData?.NucleusPro?.lifetime)}
              />
              <ComparePropsItem
                label={t(`string.translate.ys4`)}
                data={getValue(data?.NucleusPro?.neutronCrossSection, ' (b)')}
                compareData={getValue(compareData?.NucleusPro?.neutronCrossSection, ' (b)')}
              />
              <div className='compare__cubes-wrapper'>
                <NfpaCube cubeData={data?.NucleusPro?.nfpaCube} onlyDiamond={true} symbol={data?.symbol} />
                <NfpaCube cubeData={compareData?.NucleusPro?.nfpaCube} onlyDiamond={true}
                          symbol={compareData?.symbol} />
              </div>
              <ComparePropsItem
                label={t(`string.translate.nfpa_cube1`)}
                data={data?.NucleusPro?.nfpaCube ? t(getNFPA1(nfpaCube[0])) : null}
                compareData={compareData?.NucleusPro?.nfpaCube ? t(getNFPA1(compareNfpaCube[0])) : null}
                color={'#CE2D4F'}
              />
              <ComparePropsItem
                label={t(`string.translate.nfpa_cube2`)}
                data={data?.NucleusPro?.nfpaCube ? t(getNFPA2(nfpaCube[1])) : null}
                compareData={compareData?.NucleusPro?.nfpaCube ? t(getNFPA2(compareNfpaCube[1])) : null}
                color={'#218BCA'}
              />
              <ComparePropsItem
                label={t(`string.translate.nfpa_cube3`)}
                data={data?.NucleusPro?.nfpaCube ? t(getNFPA3(nfpaCube[2])) : null}
                compareData={compareData?.NucleusPro?.nfpaCube ? t(getNFPA3(compareNfpaCube[2])) : null}
                color={'#F7B801'}
              />
              <ComparePropsItem
                label={t(`string.translate.nfpa_cube4`)}
                data={data?.NucleusPro?.nfpaCube ? t(getNFPA4(nfpaCube[3])) : null}
                compareData={compareData?.NucleusPro?.nfpaCube ? t(getNFPA4(compareNfpaCube[3])) : null}
                color={'#ffffff'}
              />
              <div className='read-element__props-header read-element__props-header--prevalence'>
                {t(`string.translate.raspr`)}
              </div>
              <ComparePropsItem
                label={`${t(`string.translate.raspr1`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
                data={
                  data?.PrevalenceCommon.prevalence1 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(data?.PrevalenceCommon.prevalence1, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.PrevalenceCommon.prevalence1 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(compareData?.PrevalenceCommon.prevalence1, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={`${t(`string.translate.raspr2`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
                data={
                  data?.PrevalenceCommon.prevalence2 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(data?.PrevalenceCommon.prevalence2, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.PrevalenceCommon.prevalence2 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(compareData?.PrevalenceCommon.prevalence2, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={`${t(`string.translate.raspr3`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
                data={
                  data?.PrevalenceCommon.prevalence3 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(data?.PrevalenceCommon.prevalence3, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.PrevalenceCommon.prevalence3 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(compareData?.PrevalenceCommon.prevalence3, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={`${t(`string.translate.raspr4`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
                data={
                  data?.PrevalenceCommon.prevalence4 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(data?.PrevalenceCommon.prevalence4, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.PrevalenceCommon.prevalence4 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(compareData?.PrevalenceCommon.prevalence4, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={`${t(`string.translate.raspr5`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
                data={
                  data?.PrevalenceCommon.prevalence5 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(data?.PrevalenceCommon.prevalence5, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.PrevalenceCommon.prevalence5 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(compareData?.PrevalenceCommon.prevalence5, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
              />
              <ComparePropsItem
                label={`${t(`string.translate.raspr6`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
                data={
                  data?.PrevalenceCommon.prevalence6 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(data?.PrevalenceCommon.prevalence6, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
                compareData={
                  compareData?.PrevalenceCommon.prevalence6 ? (
                    <span
                      className='read-element__props-value'
                      dangerouslySetInnerHTML={getValuesWithHTML(compareData?.PrevalenceCommon.prevalence6, '%')}
                    />
                  ) : (
                    '----'
                  )
                }
              />
            </div>
          </>
        ) : (
          <div className='read-element__loader-wrapper'>
            <Loader itemId={itemId} size='small' />
          </div>
        )}
      </section>
    </>
  );
};

export default ComparePage;
