import i18next from 'i18next';


export const sortSearchList = (a, b) =>
  a.props['data-symbol'].localeCompare(b.props['data-symbol']);

export const search = (items, inputValue, searchParameter) => {
  if (inputValue.length === 0) {
    return items.sort((a, b) => sortSearchList(a, b));
  }

  const result = items.filter(item => {
    if (searchParameter === 'all') {
      return (
        item.props['data-name']
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1 ||
        item.props['data-latin-name']
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1 ||
        item.props['data-symbol']
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1 ||
        item.props['data-number']
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1 ||
        item.props['data-weight']
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1 ||
        (typeof item.props['data-year'] === 'string' &&
          item.props['data-year']
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) > -1)
      );
    } else {
      if (typeof item.props[`data-${searchParameter}`] === 'string') {
        return (
          item.props[`data-${searchParameter}`]
            .toLowerCase()
            .indexOf(inputValue.toLowerCase()) > -1
        );
      }
    }
    return false;
  });

  if (result.length !== 0) {
    return result.sort((a, b) => sortSearchList(a, b));
  } else {
    return (
      <div className='search__not-found'>
        <img
          className='search__not-found-image'
          src={require('../assets/images/nothing-found-icon.svg').default}
          alt=''
        />
        <p className='search__not-found-text'>
          {i18next.t('string.translate.not_found_search')}
        </p>
      </div>
    );
  }
};