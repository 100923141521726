import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import GoToHomePage from '../components/GoToHomePage.js';
import { changeCategoryColor, useOutsideClick } from '../services/functions.js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { elementCategories } from '../constants/elementCategories';
import { useRecoilState } from 'recoil';
import { allElementsState, qrModalState } from '../states/atoms';
import { useAuth } from '../hooks/useAuth';

const ElementCell = styled.div`
    min-width: 60px;
    max-width: 60px;
    height: 100%;
    border-bottom: 2px solid ${props => props.borderColor};
    padding: 3px;
    overflow: hidden;
    margin-right: 9px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: 1360px) {
        min-width: 65px;
        max-width: 65px;
    }

    @media (min-width: 1400px) {
        min-width: 68px;
        max-width: 68px;
    }

    @media (min-width: 1600px) {
        min-width: 78px;
        max-width: 78px;
    }

    @media (min-width: 1900px) {
        min-width: 92px;
        max-width: 92px;
    }
`;

const BackgroundColor = styled.div`
    position: absolute;
    width: ${props => props.bgWidth};
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${props => props.bgColor};
    border-radius: 5px;
`;

const ElementListPage = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const [elements] = useRecoilState(allElementsState);
  const history = useHistory();
  const [, setQrModal] = useRecoilState(qrModalState);

  const options = [
    {
      value: 'atom-weight',
      label: `${t('string.translate.read_atom_massa').slice(0, -1)}`,
      color: '#006DA4',
      onlyPro: false
    },
    {
      value: 'electrons', label: `${t('string.translate.text_electron').slice(0, -1)}`, color: '#F5BE25', onlyPro: false
    },
    {
      value: 'electronegativity', label: `${t('string.translate.rs1').slice(0, -1)}`, color: '#006DA4', onlyPro: false
    },
    {
      value: 'density', label: `${t('string.translate.sm4').slice(0, -1)}`, color: '#57733B', onlyPro: false
    },
    {
      value: 'protons', label: `${t('string.translate.text_proton').slice(0, -1)}`, color: '#CF6142', onlyPro: false
    },
    {
      value: 'neutrons', label: `${t('string.translate.text_neutron').slice(0, -1)}`, color: '#00803B', onlyPro: false
    },
    {
      value: 'atomic-radius', label: `${t('string.translate.at3').slice(0, -1)}`, color: '#FF8000', onlyPro: false
    },
    {
      value: 'van-der-waals-radius',
      label: `${t('string.translate.at5').slice(0, -1)}`,
      color: '#6F25A1',
      onlyPro: false
    },
    {
      value: 'covalent-radius', label: `${t('string.translate.at4').slice(0, -1)}`, color: '#CF6142', onlyPro: false
    },
    {
      value: 'molarValue', label: `${t('string.translate.sm7').replace(':', '')}`, color: '#006da1', onlyPro: true
    },
    {
      value: 'soundSpeed', label: `${t('string.translate.sm8').replace(':', '')}`, color: '#FF8600', onlyPro: true
    },
    {
      value: 'thermalConductivity',
      label: `${t('string.translate.sm10').replace(':', '')}`,
      color: '#20A4F3',
      onlyPro: true
    },
    {
      value: 'MohsHardness', label: `${t('string.translate.sm6').replace(':', '')}`, color: '#FF8600', onlyPro: true
    },
    {
      value: 'liquidDensity', label: `${t('string.translate.sm5').replace(':', '')}`, color: '#CE2D4F', onlyPro: true
    },
    {
      value: 'brinnelHardness', label: `${t('string.translate.sm2').replace(':', '')}`, color: '#43894E', onlyPro: true
    },
    {
      value: 'youngModulus', label: `${t('string.translate.sm11').replace(':', '')}`, color: '#C41E3D', onlyPro: true
    },
    {
      value: 'VickersHardness', label: `${t('string.translate.sm15').replace(':', '')}`, color: '#CE2D4F', onlyPro: true
    },
    {
      value: 'bulkModulus', label: `${t('string.translate.sm3').replace(':', '')}`, color: '#FAA916', onlyPro: true
    },
    {
      value: 'shearModulus', label: `${t('string.translate.sm14').replace(':', '')}`, color: '#D84A05', onlyPro: true
    }
  ];

  const wrapperRef = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const openDropdown = value => setDropdown(value);
  const [dropdownValue, setDropdownValue] = useState(options[0]);
  const changeDropdownValue = value => setDropdownValue(value);
  const [sortBy, setSortBy] = useState('default');
  const [sortedItems, setSortedItems] = useState([]);

  useOutsideClick(wrapperRef, openDropdown);

  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    const getItems = () => {
      let listItems = [];

      elements.forEach((element, i) => {
        const elementCategory = elementCategories[i];
        const props = {
          width: '', color: selectedOption.color, value: ''
        };

        if (selectedOption.value === 'atom-weight') {
          props.width = `${(Number(element.PropertiesCommon.elementMasse) / 335) * 100}%`;
          props.value = `${Number(element.PropertiesCommon.elementMasse).toFixed(4)} ${t('string.translate.read_gramm_moll')}`;
        } else if (selectedOption.value === 'electrons') {
          props.width = `${(Number(element.OverviewCommon.elementE) / 126) * 100}%`;
          props.value = `${element.OverviewCommon.elementE}`;
        } else if (selectedOption.value === 'protons') {
          props.width = `${(Number(element.OverviewCommon.elementP) / 126) * 100}%`;
          props.value = `${element.OverviewCommon.elementP}`;
        } else if (selectedOption.value === 'neutrons') {
          props.width = `${(Number(element.OverviewCommon.elementN) / 208) * 100}%`;
          props.value = `${element.OverviewCommon.elementN}`;
        } else if (selectedOption.value === 'atomic-radius') {
          props.width = `${(Number(element.AtomicCommon.atomicRadius) / 260) * 100}%`;
          props.value = `${element.AtomicCommon.atomicRadius} pm`;
        } else if (selectedOption.value === 'van-der-waals-radius') {
          props.width = `${(Number(element.AtomicCommon.vanDerWaalsRadius) / 348) * 100}%`;
          props.value = `${element.AtomicCommon.vanDerWaalsRadius} pm`;
        } else if (selectedOption.value === 'covalent-radius') {
          props.width = `${(Number(element.AtomicCommon.covalentRadius) / 225) * 100}%`;
          props.value = `${element.AtomicCommon.covalentRadius} pm`;
        } else if (selectedOption.value === 'electronegativity') {
          props.width = `${(Number(element.ReactivityCommon.electronegativity) / 4) * 100}%`;
          props.value = `${element.ReactivityCommon.electronegativity}`;
        } else if (isAuthenticated && selectedOption.value === 'liquidDensity') {
          props.width = `${(Number(element.AdditionalPro.liquidDensity) / 20) * 100}%`;
          props.value = `${element.AdditionalPro.liquidDensity} ${t('string.translate.read_gramm_santim')}`;
        } else if (selectedOption.value === 'density') {
          props.width = `${(Number(element.PropertiesCommon.elementDensity) / 38) * 100}%`;
          props.value = `${element.PropertiesCommon.elementDensity} ${t('string.translate.read_gramm_santim')}`;
        } else if (isAuthenticated && selectedOption.value === 'brinnelHardness') {
          props.width = `${(Number(element.AdditionalPro.brinellHardness) / 7274) * 100}%`;
          props.value = `${element.AdditionalPro.brinellHardness} MPa`;
        } else if (isAuthenticated && selectedOption.value === 'MohsHardness') {
          props.width = `${(Number(element.AdditionalPro.mohsHardness) / 9.5) * 100}%`;
          props.value = `${element.AdditionalPro.mohsHardness}`;
        } else if (isAuthenticated && selectedOption.value === 'VickersHardness') {
          props.width = `${(Number(element.AdditionalPro.vickersHardness) / 49000) * 100}%`;
          props.value = `${element.AdditionalPro.vickersHardness} MPa`;
        } else if (isAuthenticated && selectedOption.value === 'bulkModulus') {
          props.width = `${(Number(element.AdditionalPro.bulkModulus) / 654) * 100}%`;
          props.value = `${element.AdditionalPro.bulkModulus} GPa`;
        } else if (isAuthenticated && selectedOption.value === 'youngModulus') {
          props.width = `${(Number(element.AdditionalPro.youngModulus) / 528) * 100}%`;
          props.value = `${element.AdditionalPro.youngModulus} GPa`;
        } else if (isAuthenticated && selectedOption.value === 'shearModulus') {
          props.width = `${(Number(element.AdditionalPro.shearModulus) / 222) * 100}%`;
          props.value = `${element.AdditionalPro.shearModulus} GPa`;
        } else if (isAuthenticated && selectedOption.value === 'soundSpeed') {
          props.width = `${(Number(element.AdditionalPro.soundSpeed) / 18350) * 100}%`;
          props.value = `${element.AdditionalPro.soundSpeed} ${t('string.translate.sm8_value')}`;
        } else if (isAuthenticated && selectedOption.value === 'thermalConductivity') {
          props.width = `${(Number(element.AdditionalPro.thermalConductivity) / 430) * 100}%`;
          props.value = `${element.AdditionalPro.thermalConductivity} ${t('string.translate.sm10_2')}`;
        } else if (isAuthenticated && selectedOption.value === 'molarValue') {
          props.width = `${(Number(element.AdditionalPro.molarValue) / 177) * 100}%`;
          props.value = `${element.AdditionalPro.molarValue} ${t('string.translate.sm7_2')}`;
        }

        props.value = props.value.replace(null, '0');

        listItems.push(<div key={i} data-value={props.value.replace(/[^0-9,.]/g, '')}>
          <Link className='element-list__item' to={`/element/${element.number}`}>
            <ElementCell
              borderColor={() => changeCategoryColor(elementCategory)}
            >
              <span className='element-list__number'>{element.number}</span>
              {element.OverviewCommon.elementRadioactivity && <span className='radioactive' />}
              <span className='element-list__symbol'>{element.symbol}</span>
              <span className='element-list__name'>
                  {t(`table.element${element.number}.name`)}
                </span>
            </ElementCell>
            <div className='element-list__prop-wrapper'>
              <BackgroundColor bgWidth={props.width} bgColor={props.color} />
              <span
                className={`element-list__prop ${selectedOption.value === 'molarValue' && 'element-list__prop--small-font'}`}>{props.value}</span>
            </div>
          </Link>
        </div>);
      });

      if (sortBy === 'default') {
        setSortedItems(listItems);
      } else if (sortBy === 'ascending') {
        setSortedItems(listItems
          .filter(el => el.props['data-value'] !== '0')
          .sort((a, b) => {
            const aVal = +a.props['data-value'];
            const bVal = +b.props['data-value'];

            if (aVal < bVal) {
              return -1;
            }
            if (aVal > bVal) {
              return 1;
            }
            return 0;
          }));
      } else if (sortBy === 'descending') {
        setSortedItems(listItems
          .filter(el => el.props['data-value'] !== '0')
          .sort((a, b) => {
            const aVal = +a.props['data-value'];
            const bVal = +b.props['data-value'];
            if (aVal > bVal) {
              return -1;
            }
            if (aVal < bVal) {
              return 1;
            }
            return 0;
          }));
      }
    };
    getItems();
  }, [dropdownValue, sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortHandleChange = () => {
    switch (sortBy) {
      case 'default':
        setSortBy('ascending');
        return;
      case 'ascending':
        setSortBy('descending');
        return;
      case 'descending':
        setSortBy('default');
        return;
      default:
        return;
    }
  };

  return (<>
    <Helmet>
      <title>{`${t('string.translate.menu_tab3')} – ${t('string.translate.name_ab')} ${new Date().getFullYear()}`}</title>
    </Helmet>
    <GoToHomePage />
    <section className='element-list'>
      <div className='element-list__container'>
        <div className='element-list__settings-wrapper'>
          <div className='dropdown__wrapper' ref={wrapperRef}>
            <button
              className='dropdown'
              onClick={() => openDropdown(!dropdown)}
            >
                <span
                  className='element-list__category-marker'
                  style={{ backgroundColor: selectedOption.color }}
                />
              <span className='dropdown__label'>{dropdownValue.label}</span>
              <img
                className='dropdown__arrow'
                src={require('../assets/images/triangle-small-down.svg').default}
                alt=''
              />
            </button>
            {dropdown && (<div className='dropdown__menu'>
              {options.map((item, i) => (<div
                className={`dropdown__menu-item ${item.onlyPro && !isAuthenticated && 'dropdown__menu-item--disabled'}`}
                key={i}
                onClick={() => {
                  if (item.onlyPro && !isAuthenticated) {
                    history.push('/');
                    setQrModal(true);
                  }
                  changeDropdownValue(options[i]);
                  openDropdown(false);
                  setSelectedOption(options[i]);
                }}
              >
                {item.onlyPro && !isAuthenticated ?
                  <img src={require('../assets/images/filters/locked-filter-icon.svg').default} alt=''
                       className='dropdown__menu-item-icon' width={14} height={14} /> : <span
                    className='element-list__category-marker'
                    style={{ backgroundColor: item.color }}
                  />}
                {item.label}
              </div>))}
            </div>)}
          </div>
          <button
            className={`element-list__sorting-btn element-list__sorting-btn--${sortBy}`}
            onClick={sortHandleChange}
          />
        </div>
        <div className='element-list__list'>
          <TransitionGroup component={null}>
            {sortedItems.map(item => (<CSSTransition
              key={item.key}
              classNames='list-item'
              timeout={500}
            >
              {item}
            </CSSTransition>))}
          </TransitionGroup>
        </div>
      </div>
    </section>
  </>);
};

export default ElementListPage;
