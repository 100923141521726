import { getNFPA1, getNFPA2, getNFPA3, getNFPA4 } from '../services/nuclearProps';
import { useAuth } from '../hooks/useAuth';
import { useRecoilState } from 'recoil';
import { qrModalState } from '../states/atoms';
import { useTranslation } from 'react-i18next';
import { classNames } from '../utils/element';
import LockIcon from '../assets/images/filters/locked-filter-icon.svg';
import { useHistory } from 'react-router-dom';

const NfpaCube = ({ cubeData, onlyDiamond, symbol }) => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const [, setQrModal] = useRecoilState(qrModalState);

  const showQrModal = () => {
    if (!isAuthenticated) {
      history.push('/');
      setQrModal(true);
    }
  };

  let nfpaCube = [];

  if (cubeData) {
    nfpaCube = cubeData.split(',').map(item => {
      const number = Number(item);
      return isNaN(number) ? item : number;
    });
  }

  if (onlyDiamond) {
    return (
      <div className='read-element__diamond'>
        <div className='read-element__diamond-symbol'>{symbol}</div>
        <div className='read-element__diamond-item read-element__diamond-item--red'>
          <span>{!!nfpaCube.length && nfpaCube[0]}</span></div>
        <div className='read-element__diamond-item read-element__diamond-item--yellow'>
          <span>{!!nfpaCube.length && nfpaCube[2]}</span></div>
        <div className='read-element__diamond-item read-element__diamond-item--blue'>
          <span>{!!nfpaCube.length && nfpaCube[1]}</span></div>
        <div className='read-element__diamond-item read-element__diamond-item--white'><span style={{
          textDecoration: nfpaCube[3] === 'W' ? 'line-through' : 'none'
        }}>{!!nfpaCube.length && nfpaCube[3] !== '-' && nfpaCube[3]}</span></div>
      </div>
    );
  }

  return (<div className='read-element__props-2-column-wrapper'>
    <ul className='read-element__props-list--2-column'>
      <li
        className={`read-element__props-item--nfpa read-element__props-item--nfpa--red ${isAuthenticated ? classNames(cubeData) : 'read-element__props-item--locked'}`}
        onClick={() => !isAuthenticated && showQrModal()}
      >
        <div className='read-element__props-with-icon'>
          <div>
            <span className='read-element__props-key'>{t(`string.translate.nfpa_cube1`)}</span>
            <br />
            <span className='read-element__props-value'>
                {isAuthenticated ? <span>
                  {cubeData ? t(getNFPA1(nfpaCube[0])) : '----'}
                </span> : <span>{t('string.translate.read_go_pro_text')}</span>}
              </span>
          </div>
          {!isAuthenticated && <img src={LockIcon} alt='' />}
        </div>
      </li>
      <li
        className={`read-element__props-item--nfpa read-element__props-item--nfpa--blue ${isAuthenticated ? classNames(cubeData) : 'read-element__props-item--locked'}`}
        onClick={() => !isAuthenticated && showQrModal()}
      >
        <div className='read-element__props-with-icon'>
          <div>
            <span className='read-element__props-key'>{t(`string.translate.nfpa_cube2`)}</span>
            <br />
            <span className='read-element__props-value'>
                {isAuthenticated ? <span>
                  {cubeData ? t(getNFPA2(nfpaCube[1])) : '----'}
                </span> : <span>{t('string.translate.read_go_pro_text')}</span>}
              </span>
          </div>
          {!isAuthenticated && <img src={LockIcon} alt='' />}
        </div>
      </li>
      <li
        className={`read-element__props-item--nfpa read-element__props-item--nfpa--yellow ${isAuthenticated ? classNames(cubeData) : 'read-element__props-item--locked'}`}
        onClick={() => !isAuthenticated && showQrModal()}
      >
        <div className='read-element__props-with-icon'>
          <div>
            <span className='read-element__props-key'>{t(`string.translate.nfpa_cube3`)}</span>
            <br />
            <span className='read-element__props-value'>
                {isAuthenticated ? <span>
                  {cubeData ? t(getNFPA3(nfpaCube[2])) : '----'}
                </span> : <span>{t('string.translate.read_go_pro_text')}</span>}
              </span>
          </div>
          {!isAuthenticated && <img src={LockIcon} alt='' />}
        </div>
      </li>
      <li
        className={`read-element__props-item--nfpa read-element__props-item--nfpa--white ${isAuthenticated ? classNames(cubeData && nfpaCube[3] !== '-') : 'read-element__props-item--locked'}`}
        onClick={() => !isAuthenticated && showQrModal()}
      >
        <div className='read-element__props-with-icon'>
          <div>
            <span className='read-element__props-key'>{t(`string.translate.nfpa_cube4`)}</span>
            <br />
            <span className='read-element__props-value'>
                {isAuthenticated ? <span>
                  {(cubeData) ? t(getNFPA4(nfpaCube[3])) : '----'}
                </span> : <span>{t('string.translate.read_go_pro_text')}</span>}
              </span>
          </div>
          {!isAuthenticated && <img src={LockIcon} alt='' />}
        </div>
      </li>
    </ul>
    <div className='read-element__diamond'>
      <div className='read-element__diamond-item read-element__diamond-item--red'>
        <span>{!!nfpaCube.length && nfpaCube[0]}</span></div>
      <div className='read-element__diamond-item read-element__diamond-item--yellow'>
        <span>{!!nfpaCube.length && nfpaCube[2]}</span></div>
      <div className='read-element__diamond-item read-element__diamond-item--blue'>
        <span>{!!nfpaCube.length && nfpaCube[1]}</span></div>
      <div className='read-element__diamond-item read-element__diamond-item--white'><span style={{
        textDecoration: nfpaCube[3] === 'W' ? 'line-through' : 'none'
      }}>{!!nfpaCube.length && nfpaCube[3] !== '-' && nfpaCube[3]}</span></div>
    </div>
  </div>);
};

export default NfpaCube;