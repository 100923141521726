import i18next from 'i18next';
import { countries } from '../constants/countries';

export const getYearDiscovered = (yearDiscovered) => {
  if (yearDiscovered) {
    if (yearDiscovered.includes('#')) {
      return i18next.t('string.translate.read_year_old');
    } else if (yearDiscovered.includes('*')) {
      return yearDiscovered
        .replace('&lt;', '<')
        .replace('&gt;', '>')
        .replace('*', ` ${i18next.t('string.translate.read_year_bc')}`);
    } else {
      return yearDiscovered;
    }
  } else {
    return '----';
  }
};

export const getFirstOpener = (opener) => {
  if (opener) {
    const parts = opener.split('/');

    switch (parts.length) {
      case 1:
        return i18next.t(`array.first_opener.item${parts[0]}`);
      case 2:
        return `${i18next.t(`array.first_opener.item${parts[0]}`)}, ${i18next.t(`array.first_opener.item${parts[1]}`)}`;
      case 3:
        return `${i18next.t(`array.first_opener.item${parts[0]}`)}, ${i18next.t(`array.first_opener.item${parts[1]}`)}, ${i18next.t(`array.first_opener.item${parts[2]}`)}`;
      default:
        return '----';
    }
  } else {
    return '----';
  }
};

export const getCountry = (code) => {
  let countryCodes = code.split(',').map(item => item.trim());

  let result = [];

  countryCodes.forEach(countryCode => {
    const country = countries.find(country => country.code === countryCode);
    if (country) {
      let countryName = i18next.t(country.name);
      if (country.flag) {
        result.push(`${country.flag} — ${countryName}`);
      } else {
        result.push(countryName);
      }
    }
  });

  return result.join('\n');
};

export const getValue = (value, unit = '') => {
  if (value === null) {
    return '----';
  } else {
    return `${value}${unit}`;
  }
};

export const getTemperature = (tempValueCelsius = 0) => {
  if (tempValueCelsius !== null) {
    const tempValueFarenheit = (Number(tempValueCelsius) * 9.0) / 5.0 + 32.0;
    const tempValueKelvin = Number(tempValueCelsius) + 273.15;

    return { tempValueCelsius, tempValueFarenheit, tempValueKelvin };
  } else {
    return null;
  }
};

export const getTemperatureFromKelvin = (tempValueKelvin = 0) => {
  const tempValueCelsius = tempValueKelvin - 273.15;
  const tempValueFarenheit = (tempValueCelsius * 9.0) / 5.0 + 32.0;

  return { tempValueCelsius, tempValueFarenheit, tempValueKelvin };
};



export const getGroupAndPeriod = (elementGroup) => {
  if (elementGroup.includes('/') && elementGroup.length > 1) {
    let groupParts = elementGroup.split('/');
    let groupElementPeriod = groupParts[0];
    let groupElementGroup = groupParts[1];

    return {
      groupElementPeriod: groupElementPeriod, groupElementGroup: groupElementGroup
    };
  } else {
    return {
      groupElementPeriod: elementGroup, groupElementGroup: '----'
    };
  }
};

export const getOxidationStatesTop = value => {
  if (value !== '-' && value !== '0') {
    return '#205aad';
  } else if (value === '0') {
    return '#568ea3';
  } else {
    return '#2e2e39';
  }
};

export const getOxidationStatesBottom = value => {
  if (value !== '-' && value !== '0') {
    return '#b7222c';
  } else if (value === '0') {
    return '#568ea3';
  } else {
    return '#2e2e39';
  }
};

export const getIonCharge = (number, ionCharge) => {
  if (ionCharge != null) {
    let finalIon;
    if (ionCharge.includes('/')) {
      let ionPart = ionCharge.split('/');
      let ion1 = ionPart[0];
      let ion2 = ionPart[1];
      finalIon = `${i18next.t(`table.element${number}.symbol`)}${ion1.sup()}, ${i18next.t(`table.element${number}.symbol`)}${ion2.sup()}`;
    } else {
      finalIon = `${i18next.t(`table.element${number}.symbol`)}${ionCharge.sup()}`;
    }
    return {
      __html: finalIon
    };
  } else {
    return {
      __html: '----'
    };
  }
};

export const classNames = value => {
  if (value) {
    return 'read-element__props-item';
  } else {
    return 'read-element__props-item--empty';
  }
};