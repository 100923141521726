const ElectronShell = ({ data }) => {
  if (!data) return null;

  let parts = data?.split('-');
  let ob1 = parseInt(parts[0].replace(/\D/g, ''));
  let ob2 = parseInt(parts[1].replace(/\D/g, ''));
  let ob3 = parseInt(parts[2].replace(/\D/g, ''));
  let ob4 = parseInt(parts[3].replace(/\D/g, ''));
  let ob5 = parseInt(parts[4].replace(/\D/g, ''));
  let ob6 = parseInt(parts[5].replace(/\D/g, ''));
  let ob7 = parseInt(parts[6].replace(/\D/g, ''));
  let ob8 = parseInt(parts[7].replace(/\D/g, ''));

  const getAtoms = (value, radius) => {
    let array = Array(value)
      .fill(0)
      .map((item, i) => (<li className='electron-shell__atom' key={i} id={i} style={{}} />));

    for (let i = 0; i < value; i++) {
      const angle = 360 / value;
      const step = angle * i;
      array[i].props.style.transform = `rotate(${step}deg) translate(0, -${radius}px)`;
    }
    return array;
  };

  return (
    <div className='read-element__electron-shell electron-shell'>
      <div className='electron-shell__wrapper'>
        <ul className='electron-shell__center' />
        <ul className='electron-shell__circle electron-shell__circle--1'>
          {getAtoms(ob1, 19)}
        </ul>
        <ul className='electron-shell__circle electron-shell__circle--2'>
          {getAtoms(ob2, 34)}
        </ul>
        <ul className='electron-shell__circle electron-shell__circle--3'>
          {getAtoms(ob3, 49)}
        </ul>
        <ul className='electron-shell__circle electron-shell__circle--4'>
          {getAtoms(ob4, 64)}
        </ul>
        <ul className='electron-shell__circle electron-shell__circle--5'>
          {getAtoms(ob5, 79)}
        </ul>
        <ul className='electron-shell__circle electron-shell__circle--6'>
          {getAtoms(ob6, 94)}
        </ul>
        <ul className='electron-shell__circle electron-shell__circle--7'>
          {getAtoms(ob7, 109)}
        </ul>
        <ul className='electron-shell__circle electron-shell__circle--8'>
          {getAtoms(ob8, 124)}
        </ul>
      </div>
    </div>
  )
}

export default ElectronShell;
