import { useTranslation } from 'react-i18next';
import { getValuesWithHTML, halfLife } from '../services/functions';
import TsIcon0 from '../assets/images/ts0.svg';
import TsIcon1 from '../assets/images/ts1.svg';
import TsIcon2 from '../assets/images/ts2.svg';
import LockIcon from '../assets/images/filters/locked-filter-icon.svg';
import { useAuth } from '../hooks/useAuth';
import { colors } from '../constants/colors';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { qrModalState } from '../states/atoms';
import {
  getCountry,
  getTemperatureFromKelvin,
  getFirstOpener,
  getTemperature,
  getValue,
  getYearDiscovered, getGroupAndPeriod, getIonCharge, classNames
} from '../utils/element';
import ElectronShell from './ElectronShell';
import OxidationStates from './OxidationStates';
import NfpaCube from './NfpaCube';

const ReadElementProps = ({ itemId, data }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const [, setQrModal] = useRecoilState(qrModalState);

  const showQrModal = () => {
    if (!isAuthenticated) {
      history.push('/');
      setQrModal(true);
    }
  };

  //Year discovered
  const yearDiscovered = data.OverviewCommon.elementOpenedYear;

  //Melting Point
  const meltingPoint = getTemperature(data?.PropertiesCommon.elementMeltingPoint);

  //Boiling Point
  const boilingPoint = getTemperature(data?.PropertiesCommon.elementBoilingPoint);

  const elementSpectrum = data.OverviewCommon.hasSpectre;

  //Element group
  const elementGroup = data.PropertiesCommon.elementGroup;

  //Oxidation states
  const oxidationStates = data.AtomicCommon.oxidationState;

  //Ion charge
  const ionCharge = data.AtomicCommon.ionCharge;

  //Ionization Potential
  const ionizationPotential = data.AtomicCommon.ionizationPotential;

  //Atomic radius
  const atomicRadius = data.AtomicCommon.atomicRadius;

  //Covalent radius
  const covalentRadius = data.AtomicCommon.covalentRadius;

  //VanDerWaals radius
  const vanDerWaalsRadius = data.AtomicCommon.vanDerWaalsRadius;

  //Electronegativity
  const electronegativity = data.ReactivityCommon.electronegativity;

  //Atom electron energy
  const atomElectronEnergy = data.ReactivityCommon.atomElectronEnergy;

  //Electrical conductivity
  const electricalConductivity = data.ElectromagneticCommon.es_electro;

  //Electrical type
  const electricalType = data.ElectromagneticCommon.es_etype;

  //Magnetic type
  const magneticType = data.ElectromagneticCommon.es_mtype;

  // Volume magnetic susceptibility
  const volumeMagnetic = data.ElectromagneticCommon.es_omvospr;

  // Mass magnetic susceptibility
  const massMagnetic = data.ElectromagneticCommon.es_umvospr;

  // Molar magnetic susceptibility
  const molarMagnetic = data.ElectromagneticCommon.es_mmvospr;

  //Resistivity
  const resistivity = data.ElectromagneticCommon.es_udel;

  //Superconducting Point
  const superconductingPoint = getTemperatureFromKelvin(data?.ElectromagneticCommon.es_temp);

  //Grid parameters
  const gridParameters = data.GridPro.gridParams ? data.GridPro.gridParams.split(' |')[0] : null;

  //grid structure number
  let gridStructureNum = data.GridPro.gridStructureNum;

  if (gridStructureNum !== null) {
    gridStructureNum = gridStructureNum.split(' | ')[0];
  }

  //Attitude
  const attitude = data.GridPro.ratio;

  //Debye temperature
  const debyeTemperature = getTemperatureFromKelvin(data?.GridPro.debyeTemperature);

  //Universe contains
  const prevalence1 = data.PrevalenceCommon.prevalence1;

  //Sun contains
  const prevalence2 = data.PrevalenceCommon.prevalence2;

  //Oceans contains
  const prevalence3 = data.PrevalenceCommon.prevalence3;

  //Human body contains
  const prevalence4 = data.PrevalenceCommon.prevalence4;

  //The Earth's crust consists
  const prevalence5 = data.PrevalenceCommon.prevalence5;

  //Meteorites consists
  const prevalence6 = data.PrevalenceCommon.prevalence6;

  //UNITS
  //gramm moll
  const massUnit = t(`string.translate.read_gramm_moll`); // g/moll
  const elementDensityUnit = t(`string.translate.read_gramm_santim`); //g/cm3
  const blockUnit = t(`string.translate.read_group_block2`).replace('%1$s', ''); //block
  const ionizationPotentialUnit = t(`string.translate.at7_2`); // eV
  const electronAffinityUnit = t(`string.translate.electro_column3`); // kJ/mole
  const electricalConductivityUnit = t(`string.translate.es1_value`); // S/m
  const molarMagneticUnit = t(`string.translate.es6_value`); // m3/mol
  const resistivityUnit = '(Ω · m)'; // omega * m

  const getPhaseImage = (value) => {
    switch (value) {
      case 0:
        return (<div className='read-element__property-icon read-element__property-icon--ts0'>
          <img src={TsIcon0} alt='' />
        </div>);
      case 1:
        return (<div className='read-element__property-icon read-element__property-icon--ts1'>
          <img src={TsIcon1} alt='' />
        </div>);
      case 2:
        return (<div className='read-element__property-icon read-element__property-icon--ts2'>
          <img src={TsIcon2} alt='' />
        </div>);
      default:
        return;
    }
  };

  return (<>
    <div className='read-element__props-header read-element__props-header--overview'>
      {t(`string.translate.read_obsh_head`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.read_latin_name`)}
            </span>
          <br />

          <span className='read-element__props-value'>
              {data.OverviewCommon.latinName || '----'}
            </span>
        </li>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.read_eng_name`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {data.OverviewCommon.englishName || '----'}
            </span>
        </li>
        <li className={classNames(yearDiscovered)}>
            <span className='read-element__props-key'>
              {t(`string.translate.read_year_open`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getYearDiscovered(yearDiscovered)}
            </span>
        </li>
        <li className={classNames(data.OverviewCommon.elementOpener)}>
            <span className='read-element__props-key'>
              {t(`string.translate.read_first_opener`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getFirstOpener(data.OverviewCommon.elementOpener)}
            </span>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.OverviewCommon?.countryOpener) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.read_opened_country`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span style={{ whiteSpace: 'pre' }}>
                  {data?.OverviewCommon?.countryOpener ? getCountry(data?.OverviewCommon?.countryOpener) : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li className={classNames(data.OverviewCommon.elementPrice)}>
            <span className='read-element__props-key'>
              {t(`string.translate.cost`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(data.OverviewCommon.elementPrice, ` USD`)}
            </span>
        </li>
        <li className={classNames(data.OverviewCommon.casNumber)}>
            <span className='read-element__props-key'>
              {t(`string.translate.sm12`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(data.OverviewCommon.casNumber, ``)}
            </span>
        </li>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.el_obolochka`)}
            </span>
          <br />
          <span className='read-element__props-value'>{data.OverviewCommon.elementShell}</span>
        </li>
      </ul>
      <ElectronShell data={data.OverviewCommon.elementShell} />
      <div className='read-element__anc-table'>
        <div className='read-element__anc-item read-element__anc-item--electron'>
          <div className='read-element__anc-table-key read-element__anc-table-key--electron'>
            {t(`string.translate.text_electron`)}
          </div>
          <div className='read-element__anc-table-value read-element__anc-table-value--electron'>
            {data.OverviewCommon.elementE}
          </div>
        </div>
        <div className='read-element__anc-item read-element__anc-item--proton'>
          <div className='read-element__anc-table-key read-element__anc-table-key--proton'>
            {t(`string.translate.text_proton`)}
          </div>
          <div className='read-element__anc-table-value read-element__anc-table-value--proton'>
            {data.OverviewCommon.elementP}
          </div>
        </div>
        <div className='read-element__anc-item read-element__anc-item--neutron'>
          <div className='read-element__anc-table-key read-element__anc-table-key--neutron'>
            {t(`string.translate.text_neutron`)}
          </div>
          <div className='read-element__anc-table-value read-element__anc-table-value--neutron'>
            {data.OverviewCommon.elementN}
          </div>
        </div>
      </div>
    </div>
    <div className='read-element__props-header read-element__props-header--properties'>
      {t(`string.translate.calc_properties`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.read_atom_number`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {itemId}
            </span>
        </li>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.read_atom_massa`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {`${data.PropertiesCommon.elementMasse} ${massUnit}`}
            </span>
        </li>
        <li className={classNames(data.PropertiesCommon.elementDensity)}>
            <span className='read-element__props-key'>
              {t(`string.translate.read_density`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(data.PropertiesCommon.elementDensity, ` ${elementDensityUnit}`)}
            </span>
        </li>
        <li
          className={meltingPoint.tempValueCelsius === '**' ? 'read-element__props-item--empty' : 'read-element__props-item'}
        >
            <span className='read-element__props-key'>
              {t(`string.translate.read_temp1`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {meltingPoint.tempValueCelsius !== '**' ? (<span>
                  {meltingPoint.tempValueCelsius}
                <span className='celsius'>°C</span> ={' '}
                {meltingPoint.tempValueFarenheit.toFixed(2)}
                <span className='fahrenheit'>°F</span> ={' '}
                {meltingPoint.tempValueKelvin.toFixed(2)}
                <span className='kelvin'>K</span>
                </span>) : ('----')}
            </span>
        </li>
        <li
          className={boilingPoint.tempValueCelsius === '**' ? 'read-element__props-item--empty' : 'read-element__props-item'}
        >
            <span className='read-element__props-key'>
              {t(`string.translate.read_temp2`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {boilingPoint.tempValueCelsius !== '**' ? (<span>
                  {boilingPoint.tempValueCelsius}
                <span className='celsius'>°C</span> ={' '}
                {boilingPoint.tempValueFarenheit.toFixed(2)}
                <span className='fahrenheit'>°F</span> ={' '}
                {boilingPoint.tempValueKelvin.toFixed(2)}
                <span className='kelvin'>K</span>
                </span>) : ('----')}
            </span>
        </li>
        <li className={classNames(data.PropertiesCommon.elValence)}>
            <span className='read-element__props-key'>
              {t(`string.translate.rs2`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(data.PropertiesCommon.elValence, '')}
            </span>
        </li>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.read_period_group`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getGroupAndPeriod(elementGroup).groupElementPeriod}
            </span>
        </li>
        <li
          className={getGroupAndPeriod(elementGroup).groupElementGroup === '----' ? 'read-element__props-item--empty' : 'read-element__props-item'}
        >
            <span className='read-element__props-key'>
              {t(`string.translate.read_group_group`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getGroupAndPeriod(elementGroup).groupElementGroup.includes('+') ? getGroupAndPeriod(elementGroup).groupElementGroup.replace('+', `${t('string.translate.element_group_a')}`) : null}
            {getGroupAndPeriod(elementGroup).groupElementGroup.includes('=') ? getGroupAndPeriod(elementGroup).groupElementGroup.replace('=', `${t('string.translate.element_group_b')}`) : null}
            </span>
        </li>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.read_group_block1`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {`${data.PropertiesCommon.elementBlock} ${blockUnit}`}
            </span>
        </li>
        <li className={classNames(elementSpectrum)}>
            <span className='read-element__props-key'>
              {t(`string.translate.read_spectr`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {(data && data.number === itemId && elementSpectrum) ? (<picture>
                <source
                  type='image/webp'
                  srcSet={`${require(`../assets/images/emission-spectrum-webp/element_spec_${itemId}.webp`)}`}
                />
                <img
                  className='read-element__spectrum-image'
                  src={require(`../assets/images/emission-spectrum/element_spec_${itemId}.jpg`)}
                  alt=''
                />
              </picture>) : ('----')}
            </span>
        </li>
      </ul>
    </div>
    <div className='read-element__props-header read-element__props-header--termodynamic-props'>
      {t(`string.translate.ts0`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className={classNames(data?.PropertiesCommon?.aggregationState)}>
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ts1`)}</span>
              <br />
              <span className='read-element__props-value'>
                <span>
                  {data?.PropertiesCommon?.aggregationState !== null ? t(`array.array_ts.item${data?.PropertiesCommon?.aggregationState}`) : '----'}
                </span>
              </span>
            </div>
            {getPhaseImage(data.PropertiesCommon.aggregationState)}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.ThermoPro?.fusionHeat) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ts2`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.ThermoPro?.fusionHeat ? `${data.ThermoPro.fusionHeat} ${t('string.translate.ts2_value')}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.ThermoPro?.specificHeat) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ts3`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.ThermoPro?.specificHeat ? `${data.ThermoPro.specificHeat} ${t('string.translate.ts3_value')}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.ThermoPro?.thermalExpansion) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ts4`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                    {data?.ThermoPro?.thermalExpansion ?
                      <span>
                        <span
                          dangerouslySetInnerHTML={{ __html: data?.ThermoPro?.thermalExpansion }} /> (°C<sup>-1</sup>)
                      </span> : '----'}
                  </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.ThermoPro?.vaporizationHeat) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ts5`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.ThermoPro?.vaporizationHeat ? `${data.ThermoPro.vaporizationHeat} ${t('string.translate.ts2_value')}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
      </ul>
    </div>
    <div className='read-element__props-header read-element__props-header--atomic-props'>
      {t(`string.translate.at0`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.el_config`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {data.OverviewCommon.elementConfiguration}
            </span>
        </li>
        <OxidationStates oxidationStates={oxidationStates} />
        <li
          className={getIonCharge(itemId, ionCharge).__html === '----' ? 'read-element__props-item--empty' : 'read-element__props-item'}
        >
            <span className='read-element__props-key'>
              {t(`string.translate.at6`)}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getIonCharge(itemId, ionCharge)}
          />
        </li>
        <li className={classNames(ionizationPotential)}>
            <span className='read-element__props-key'>
              {t(`string.translate.at7`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(ionizationPotential, ` ${ionizationPotentialUnit}`)}
            </span>
        </li>
        <li className={classNames(atomicRadius)}>
            <span className='read-element__props-key'>
              {t(`string.translate.at3`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {`${getValue(atomicRadius, ' (pm)')}`}
            </span>
        </li>
        <li className={classNames(covalentRadius)}>
            <span className='read-element__props-key'>
              {t(`string.translate.at4`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(covalentRadius, ' (pm)')}
            </span>
        </li>
        <li className={classNames(vanDerWaalsRadius)}>
            <span className='read-element__props-key'>
              {t(`string.translate.at5`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(vanDerWaalsRadius, ' (pm)')}
            </span>
        </li>
      </ul>
    </div>
    <div className='read-element__props-header read-element__props-header--electromagnetic-props'>
      {t(`string.translate.es0`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className={classNames(electricalConductivity)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es1`)}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(electricalConductivity, ` ${electricalConductivityUnit}`)}
          />
        </li>
        <li className={classNames(electricalType)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es2`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {electricalType !== null ? t(`array.es_etype.item${electricalType - 1}`) : '----'}
            </span>
        </li>
        <li className={classNames(magneticType)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es3`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {magneticType !== null ? t(`array.es_mtype.item${magneticType - 1}`) : '----'}
            </span>
        </li>
        <li className={classNames(volumeMagnetic)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es4`)}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(volumeMagnetic, '')}
          />
        </li>
        <li className={classNames(massMagnetic)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es5`)}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(massMagnetic, '')}
          />
        </li>
        <li className={classNames(molarMagnetic)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es6`)}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(molarMagnetic, ` ${molarMagneticUnit}`)}
          />
        </li>
        <li className={classNames(resistivity)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es7`)}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(resistivity, ` ${resistivityUnit}`)}
          />
        </li>
        <li className={classNames(superconductingPoint.tempValueKelvin)}>
            <span className='read-element__props-key'>
              {t(`string.translate.es8`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {superconductingPoint.tempValueKelvin !== null ? (<span>
                  {superconductingPoint.tempValueCelsius.toFixed(2)}
                <span className='celsius'>°C</span> ={' '}
                {superconductingPoint.tempValueFarenheit.toFixed(2)}
                <span className='fahrenheit'>°F</span> ={' '}
                {superconductingPoint.tempValueKelvin}
                <span className='kelvin'>K</span>
                </span>) : ('----')}
            </span>
        </li>
      </ul>
    </div>
    <div className='read-element__props-header read-element__props-header--grid-parameters'>
      {t(`string.translate.kr0`)}
    </div>
    <div
      className={gridStructureNum === null ? 'read-element__props-wrapper' : 'read-element__props-wrapper--2-column'}
    >
      <ul
        className={gridStructureNum === null ? 'read-element__props-list' : 'read-element__props-list--2-column'}
      >
        <li className={classNames(gridStructureNum)}>
            <span className='read-element__props-key'>
              {t(`string.translate.kr1`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {gridStructureNum !== null ? t(`array.reshetka.item${gridStructureNum - 1}`) : '----'}
            </span>
        </li>
        <li className={classNames(gridParameters)}>
            <span className='read-element__props-key'>
              {t(`string.translate.kr2`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(gridParameters, '')}
            </span>
        </li>
        <li className={classNames(attitude)}>
            <span className='read-element__props-key'>
              {t(`string.translate.kr3`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(attitude, '')}
            </span>
        </li>
      </ul>
      {gridStructureNum !== null ? (
        <picture>
          <source
            type='image/webp'
            srcSet={`${require(`../assets/images/crystal-grid/webp/struct${gridStructureNum}.webp`)} 1x`}
          />
          <img
            className='read-element__crystal-grid-image'
            src={require(`../assets/images/crystal-grid/gif/struct${gridStructureNum}.gif`)}
            alt=''
          />
        </picture>
      ) : ('')}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className={classNames(debyeTemperature.tempValueKelvin)}>
            <span className='read-element__props-key'>
              {t(`string.translate.kr4`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {debyeTemperature.tempValueKelvin !== null ? (<span>
                  {debyeTemperature.tempValueCelsius.toFixed(2)}
                <span className='celsius'>°C</span> ={' '}
                {debyeTemperature.tempValueFarenheit.toFixed(2)}
                <span className='fahrenheit'>°F</span> ={' '}
                {debyeTemperature.tempValueKelvin}
                <span className='kelvin'>K</span>
                </span>) : ('----')}
            </span>
        </li>
      </ul>
    </div>
    <div className='read-element__props-header read-element__props-header--additional-info'>
      {t(`string.translate.sm0`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.elementColor) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div className='read-element__color-wrapper'>
              <span className='read-element__props-key'>{t(`string.translate.sm1`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {
                    data?.AdditionalPro?.elementColor ?
                      <div className='read-element__element-color'
                           style={{ backgroundColor: `#${colors[data?.AdditionalPro?.elementColor]}` }} /> :
                      '----'
                  }
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li className={classNames(data.OverviewCommon.casNumber)}>
            <span className='read-element__props-key'>
              {t(`string.translate.sm12`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(data.OverviewCommon.casNumber)}
            </span>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.numberCID) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm16`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.numberCID ? data?.AdditionalPro?.numberCID : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.numberRTEC) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm17`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.numberRTEC ? data?.AdditionalPro?.numberRTEC : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.brinellHardness) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm2`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.brinellHardness ? `${data?.AdditionalPro?.brinellHardness} (MPa)` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.mohsHardness) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm6`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.mohsHardness ? data?.AdditionalPro?.mohsHardness : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.vickersHardness) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm15`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.vickersHardness ? data?.AdditionalPro?.vickersHardness : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.bulkModulus) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm3`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.bulkModulus ? `${data?.AdditionalPro?.bulkModulus} (GPa)` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.youngModulus) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm11`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.youngModulus ? `${data?.AdditionalPro?.youngModulus} (GPa)` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.liquidDensity) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm5`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.liquidDensity ? `${data?.AdditionalPro?.liquidDensity} ${t('string.translate.read_gramm_santim')}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.molarValue) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm7`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.molarValue ? `${data?.AdditionalPro?.molarValue} ${t('string.translate.sm7_2')}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.poissonRatio) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm13`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.poissonRatio ? `${data?.AdditionalPro?.poissonRatio} (μ)` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.shearModulus) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm14`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.shearModulus ? `${data?.AdditionalPro?.shearModulus} (GPa)` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.soundSpeed) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm8`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.soundSpeed ? `${data?.AdditionalPro?.soundSpeed} ${t('string.translate.sm8_value')}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.refractiveIndex) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm9`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.refractiveIndex ? `${data?.AdditionalPro?.refractiveIndex}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.AdditionalPro?.thermalConductivity) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.sm10`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.AdditionalPro?.thermalConductivity ? `${data?.AdditionalPro?.thermalConductivity} ${t('string.translate.sm10_2')}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
      </ul>
    </div>

    <div className='read-element__props-header read-element__props-header--reactivity'>
      {t(`string.translate.rs0`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className={classNames(electronegativity)}>
            <span className='read-element__props-key'>
              {t(`string.translate.rs1`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(electronegativity, '')}
          </span>
        </li>
        <li className={classNames(data.PropertiesCommon.elValence)}>
            <span className='read-element__props-key'>
              {' '}
              {t(`string.translate.rs2`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(data.PropertiesCommon.elValence, '')}
            </span>
        </li>
        <li className={classNames(atomElectronEnergy)}>
            <span className='read-element__props-key'>
              {t(`string.translate.rs3`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {getValue(atomElectronEnergy, ` ${electronAffinityUnit}`)}
            </span>
        </li>
      </ul>
    </div>
    <div className='read-element__props-header read-element__props-header--nuclear-props'>
      {t(`string.translate.ys0`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.ys1`)}
            </span>
          <br />
          <span className='read-element__props-value'>
              {data.OverviewCommon.elementRadioactivity ? t('string.translate.ys_yes') : t('string.translate.ys_no')}
            </span>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.NucleusPro?.halfLife) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ys2`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.NucleusPro?.halfLife ? `${halfLife(data?.NucleusPro?.halfLife)}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.NucleusPro?.lifetime) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ys3`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.NucleusPro?.lifetime ? `${halfLife(data?.NucleusPro?.lifetime)}` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
        <li
          className={isAuthenticated ? classNames(data?.NucleusPro?.neutronCrossSection) : 'read-element__props-item--locked'}
          onClick={() => !isAuthenticated && showQrModal()}
        >
          <div className='read-element__props-with-icon'>
            <div>
              <span className='read-element__props-key'>{t(`string.translate.ys4`)}</span>
              <br />
              <span className='read-element__props-value'>
                {isAuthenticated ?
                  <span>
                  {data?.NucleusPro?.neutronCrossSection ? `${data?.NucleusPro?.neutronCrossSection} (b)` : '----'}
                </span> :
                  <span>{t('string.translate.read_go_pro_text')}</span>
                }
              </span>
            </div>
            {!isAuthenticated && <img src={LockIcon} alt='' />}
          </div>
        </li>
      </ul>
      <NfpaCube cubeData={data?.NucleusPro?.nfpaCube} />
    </div>
    <div className='read-element__props-header read-element__props-header--prevalence'>
      {t(`string.translate.raspr`)}
    </div>
    <div className='read-element__props-wrapper'>
      <ul className='read-element__props-list'>
        <li className={classNames(prevalence1)}>
            <span className='read-element__props-key'>
              {`${t(`string.translate.raspr1`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(prevalence1, '%')}
          />
        </li>
        <li className={classNames(prevalence2)}>
            <span className='read-element__props-key'>
              {`${t(`string.translate.raspr2`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(prevalence2, '%')}
          />
        </li>
        <li className={classNames(prevalence3)}>
            <span className='read-element__props-key'>
              {`${t(`string.translate.raspr3`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(prevalence3, '%')}
          />
        </li>
        <li className={classNames(prevalence4)}>
            <span className='read-element__props-key'>
              {`${t(`string.translate.raspr4`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(prevalence4, '%')}
          />
        </li>
        <li className={classNames(prevalence5)}>
            <span className='read-element__props-key'>
              {`${t(`string.translate.raspr5`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(prevalence5, '%')}
          />
        </li>
        <li className={classNames(prevalence6)}>
            <span className='read-element__props-key'>
              {`${t(`string.translate.raspr6`)} "${t(`table.element${itemId}.name`)}" ${t(`string.translate.na`)} `}
            </span>
          <br />
          <span
            className='read-element__props-value'
            dangerouslySetInnerHTML={getValuesWithHTML(prevalence6, '%')}
          />
        </li>
      </ul>
    </div>
  </>);
};

export default ReadElementProps;
