import styled from 'styled-components';
import { getOxidationStatesBottom, getOxidationStatesTop } from '../utils/element';
import { useTranslation } from 'react-i18next';

const OxidationStatesItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    background-color: ${props => props.backgroundColor};
    border-radius: 100%;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 13px;
`;

const OxidationStates = ({ oxidationStates, elementName }) => {
  const { t } = useTranslation();

  let oxidationPart = oxidationStates ? oxidationStates.split(',') : [];
  let m1 = !!oxidationPart.length && oxidationPart[4].replace(' ', '');
  let m2 = !!oxidationPart.length && oxidationPart[3].replace(' ', '');
  let m3 = !!oxidationPart.length && oxidationPart[2].replace(' ', '');
  let m4 = !!oxidationPart.length && oxidationPart[1].replace(' ', '');
  let m5 = !!oxidationPart.length && oxidationPart[0].replace(' ', '');
  let zz = !!oxidationPart.length && oxidationPart[5].replace(' ', '');
  let p1 = !!oxidationPart.length && oxidationPart[6].replace(' ', '');
  let p2 = !!oxidationPart.length && oxidationPart[7].replace(' ', '');
  let p3 = !!oxidationPart.length && oxidationPart[8].replace(' ', '');
  let p4 = !!oxidationPart.length && oxidationPart[9].replace(' ', '');
  let p5 = !!oxidationPart.length && oxidationPart[10].replace(' ', '');
  let p6 = !!oxidationPart.length && oxidationPart[11].replace(' ', '');
  let p7 = !!oxidationPart.length && oxidationPart[12].replace(' ', '');
  let p8 = !!oxidationPart.length && oxidationPart[13].replace(' ', '');
  let p9 = !!oxidationPart.length && oxidationPart[14].replace(' ', '');

  return (
    <div className='read-element__props-item'>
            <span className='read-element__props-key'>
              {t(`string.translate.at1`)} {elementName}
            </span>
      <br />
      <div className='read-element__props-value'>
        <ul className='read-element__oxidation-states-list read-element__oxidation-states-list--top'>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesTop(zz)}
          >
            {zz}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesTop(m1)}
          >
            {m1 !== '-' ? `-${m1}` : m1}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesTop(m2)}
          >
            {m2 !== '-' ? `-${m2}` : m2}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesTop(m3)}
          >
            {m3 !== '-' ? `-${m3}` : m4}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesTop(m4)}
          >
            {m4 !== '-' ? `-${m4}` : m4}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesTop(m5)}
          >
            {m5 !== '-' ? `-${m5}` : m5}
          </OxidationStatesItem>
        </ul>
        <ul className='read-element__oxidation-states-list'>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p1)}
          >
            {p1 !== '-' ? `+${p1}` : p1}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p2)}
          >
            {p2 !== '-' ? `+${p2}` : p2}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p3)}
          >
            {p3 !== '-' ? `+${p3}` : p3}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p4)}
          >
            {p4 !== '-' ? `+${p4}` : p4}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p5)}
          >
            {p5 !== '-' ? `+${p5}` : p5}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p6)}
          >
            {p6 !== '-' ? `+${p6}` : p6}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p7)}
          >
            {p7 !== '-' ? `+${p7}` : p7}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p8)}
          >
            {p8 !== '-' ? `+${p8}` : p8}
          </OxidationStatesItem>
          <OxidationStatesItem
            backgroundColor={getOxidationStatesBottom(p9)}
          >
            {p9 !== '-' ? `+${p9}` : p9}
          </OxidationStatesItem>
        </ul>
      </div>
    </div>
  );
};

export default OxidationStates;