import React from 'react';

const extractText = (input) => {
  if (typeof input === 'string') {
    return input;
  } else if (typeof input === 'number') {
    return input.toString();
  } else if (React.isValidElement(input)) {
    if (input.props && input.props.dangerouslySetInnerHTML) {
      return input.props.dangerouslySetInnerHTML.__html;
    }
    return extractText(input.props.children);
  } else if (Array.isArray(input)) {
    return input.map(extractText).join('');
  } else if (input == null) {
    return '';
  } else {
    return String(input);
  }
};

const prepareToCalc = (str) => {
  let out = String(str);

  out = out.replace(/°C/g, '')
    .replace(/°F/g, '')
    .replace(/K/g, '');

  out = out.replace(/\n/g, '');

  out = out.replace(/ \(.+?\)/g, '');

  out = out.replace(/ USD/g, '')
    .replace(/ %/g, '');


  out = out.replace(/<font color=#067BC2>°C<\/font><sup><small>-1<\/small><\/sup>/g, '');

  if (out.includes(' ⋅ 10')) {
    out = out.replace(/ ⋅ 10/g, 'E')
      .replace(/<sup><small>/g, '')
      .replace(/<\/small><\/sup>/g, '');
  }

  let blockStr = 'R.string.read_group_block2'.replace('%1$s', '');
  if (out.includes(blockStr)) {
    out = out.replace(new RegExp(blockStr, 'g'), '')
      .replace(/<font.+?>/g, '')
      .replace(/s/g, '1')
      .replace(/p/g, '2')
      .replace(/d/g, '3')
      .replace(/f/g, '4');
  }

  const rimArr = ['VIII', 'VII', 'VI', 'V', 'IV', 'III', 'II', 'I'];
  for (let i = rimArr.length - 1; i >= 0; i--) {
    const roman = rimArr[i];
    out = out.replace(new RegExp(roman, 'g'), String(i + 1));
  }

  return out;
};

const compareTextValues = (rawValue1, rawValue2) => {
  const text1 = extractText(rawValue1);
  const text2 = extractText(rawValue2);

  const value1 = prepareToCalc(text1) || '';
  const value2 = prepareToCalc(text2) || '';

  if (value1 === '----' && value2 === '----') {
    return { isValue1Bigger: false, isValue2Bigger: false };
  } else if (value1 === '----' && value2 !== '----') {
    return { isValue1Bigger: false, isValue2Bigger: true };
  } else if (value2 === '----' && value1 !== '----') {
    return { isValue1Bigger: true, isValue2Bigger: false };
  }

  const firstNumber = parseFloat(value1);
  const secondNumber = parseFloat(value2);

  if (!isNaN(firstNumber) && !isNaN(secondNumber)) {
    return {
      isValue1Bigger: firstNumber > secondNumber,
      isValue2Bigger: firstNumber < secondNumber
    };
  } else {
    if (value1 === '' && value2 === '') return { isValue1Bigger: false, isValue2Bigger: false };
    if (value1 === '') return { isValue1Bigger: false, isValue2Bigger: true };
    if (value2 === '') return { isValue1Bigger: true, isValue2Bigger: false };

    if (value1.length === value2.length) {
      return {
        isValue1Bigger: value1 > value2,
        isValue2Bigger: value1 < value2
      };
    }
    return {
      isValue1Bigger: value1.length > value2.length,
      isValue2Bigger: value1.length < value2.length
    };
  }
};

const ComparePropsItem = ({ label, data, compareData, color, withoutCompare = false }) => {
  const { isValue1Bigger, isValue2Bigger } = withoutCompare
    ? { isValue1Bigger: false, isValue2Bigger: false }
    : compareTextValues(data, compareData);

  return (
    <div className='compare__props-wrapper'>
      <div className='compare__props-item'>
        {color && (
          <span
            className='compare__props-item-color'
            style={{ backgroundColor: color }}
          />
        )}
        <span>{label}</span>
      </div>
      <div
        className={`compare__props-item ${
          (!data || data === '----') ? 'compare__props-item--empty' : ''
        } ${isValue1Bigger ? 'compare__props-over' : ''}`}
      >
        <div><span>{data || '----'}</span></div>
      </div>
      <div
        className={`compare__props-item ${
          (!compareData || compareData === '----') ? 'compare__props-item--empty' : ''
        } ${isValue2Bigger ? 'compare__props-over' : ''}`}
      >
        <div><span>{compareData || '----'}</span></div>
      </div>
    </div>
  );
};

export default ComparePropsItem;
