import { useEffect } from 'react';
import { elementCategories } from '../constants/elementCategories';
import i18next from 'i18next';

export const addShadowOnScroll = (setStartState, setEndState, ref) => {
  const onScroll = () => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      offsetHeight = 0
    } = ref.current || {};
    setStartState(scrollTop > 0);
    setEndState(scrollTop + offsetHeight < scrollHeight);
  };
  onScroll();
  const node = ref.current;
  node.addEventListener('scroll', onScroll);
  return () => {
    node.removeEventListener('scroll', onScroll);
  };
};

export const useOutsideClick = (ref, setClose) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClose(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, setClose]);
};

export const changeCategoryColor = category => {
  switch (category) {
    case 'A':
      return '#CE2D4F';
    case 'B':
      return '#20A4F3';
    case 'C':
      return '#FF8600';
    case 'D':
      return '#006DA1';
    case 'E':
      return '#C41E3D';
    case 'G':
      return '#FAA916';
    case 'H':
      return '#43894E';
    case 'I':
      return '#662e9b';
    case 'J':
      return '#D84A05';
    case 'K':
      return '#52713F';
    case 'L':
      return '#F5F5F5';
    default:
      return '#F5F5F5';
  }
};

export const changeWhiteColor = category => {
  switch (category) {
    case 'L':
      return '#000000';
    default:
      return '#F5F5F5';
  }
};

export const getValuesWithHTML = (value, unit = '') => {
  if (value === null) {
    return {
      __html: '----'
    };
  } else {
    return {
      __html: `${value}${unit}`
    };
  }
};

export const setFontSizeVariable = () => {
  const fontSize = localStorage.getItem('readElementFontSize');
  switch (fontSize) {
    case '0':
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '12px'
      );
      break;
    case '1':
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '14px'
      );
      break;
    case '2':
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '16px'
      );
      break;
    default:
      document.documentElement.style.setProperty(
        '--read-element-font-size',
        '14px'
      );
      break;
  }
};

export const getCategoryName = (itemId) => {
  switch (elementCategories[itemId - 1]) {
    case 'A':
      return i18next.t(`string.translate.read_cat1`);
    case 'B':
      return i18next.t(`string.translate.read_cat2`);
    case 'C':
      return i18next.t(`string.translate.read_cat3`);
    case 'D':
      return i18next.t(`string.translate.read_cat4`);
    case 'E':
      return i18next.t(`string.translate.read_cat5`);
    case 'G':
      return i18next.t(`string.translate.read_cat6`);
    case 'H':
      return i18next.t(`string.translate.read_cat7`);
    case 'I':
      return i18next.t(`string.translate.read_cat8`);
    case 'J':
      return i18next.t(`string.translate.read_cat9`);
    case 'K':
      return i18next.t(`string.translate.read_cat10`);
    case 'L':
      return i18next.t(`string.translate.read_cat11`);
    default:
      return i18next.t(`string.translate.read_cat11`);
  }
};

export const halfLife = value => {
  if (!value || typeof value !== 'string') {
    return null;
  }

  if (value === '∞') {
    return value;
  }

  const arr = value.split('/');
  const units = {
    '1': i18next.t('string.translate.isotop_year'),
    '2': i18next.t('string.translate.isotop_day'),
    '3': i18next.t('string.translate.isotop_hour'),
    '4': i18next.t('string.translate.isotop_min'),
    '5': i18next.t('string.translate.isotop_sec')
  };

  return arr.length === 2 && units[arr[1]] ? `${arr[0]} ${units[arr[1]]}` : null;
};


